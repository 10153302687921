import { Label, SelectField } from "@aws-amplify/ui-react";
import { Box, Button, Grid, Hidden, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    API_Authenticate,
    API_GetScheduleAddresses,
    GetUserName,
} from "../../services/GetInvoiceData";
import { GreenButton } from "../Buttons";
import CartItem from "../CartItem/CartItem";
import { CustomDivider } from "../CustomDivider";
import LoadingSpinner from "../LoadingSpinner";
import { ScheduleAddress, WebInventoryItem } from "../StoreItems";
import CartInfo from "./CartInfo";

// Free Material-UI Template
// https://react.school/material-ui/templates

type Props = {
    cartItems: WebInventoryItem[];
    addToCart: (clickedItem: WebInventoryItem) => void;
    removeFromCart: (citemno: string, nqty?: number, bAll?: boolean) => void;
    checkout: () => void;
    address: ScheduleAddress;
    setAddress: React.Dispatch<React.SetStateAction<ScheduleAddress>>;
};

const getScheduleAddress = async (username: string) => {
    const token = await API_Authenticate();
    const addrs = await API_GetScheduleAddresses(token, username, "D");
    if (!addrs) {
        console.log("Error getting schedule addresses. (null)");
        return [];
    }
    if (!addrs.IsSuccessful) {
        console.log(
            "Error getting schedule addresses. (" + addrs.ErrorCode + ": " + addrs.Error + ")",
        );
        return [];
    }
    return addrs.Addresses;
};

const Cart: React.FC<Props> = ({
    cartItems,
    addToCart,
    removeFromCart,
    checkout,
    address,
    setAddress,
}) => {
    const [poNumber, setPoNumber] = React.useState("");
    const [deliveryInstructions, setDeliveryInstructions] = React.useState("");
    const [paymentType, setPaymentType] = React.useState("net");
    const [ccProfileID, setCcProfileID] = React.useState("");
    const [addresses, setAddresses] = React.useState<ScheduleAddress[]>([]);
    const [addressLabel, setAddressLabel] = React.useState<string>("");
    const [width, setWidth] = React.useState<number>(window.innerWidth);
    const [loading, setLoading] = React.useState(false);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;
    const navigate = useNavigate();

    const keepShopping = () => {
        navigate("/products");
    };

    useEffect(() => {
        getScheduleAddress(GetUserName()).then((addrs) => {
            setAddresses(addrs);
        });
        setPoNumber(localStorage.getItem("poNumber") || "");
        setDeliveryInstructions(localStorage.getItem("deliveryInstructions") || "");
        setPaymentType(localStorage.getItem("paymentType") || "net");
        setCcProfileID(localStorage.getItem("ccProfileID") || "");
    }, []);

    const handleCheckout = () => {
        console.log("Cart: checkout: ", cartItems);
        console.log("Cart: address: ", address);

        if (cartItems.length === 0) {
            alert("You can't checkout an empty cart.");
            return;
        }

        if (!address || !address?.ccustno || address?.ccustno === "") {
            alert("You need to pick a Schedule/Address before you can checkout.");
            return;
        }

        if (paymentType === "cc" && ccProfileID === "") {
            alert("You need to pick a Credit Card before you can checkout.");
            return;
        }

        localStorage.setItem("poNumber", poNumber);
        localStorage.setItem("deliveryInstructions", deliveryInstructions);
        localStorage.setItem("paymentType", paymentType);
        localStorage.setItem("ccProfileID", ccProfileID);

        checkout();
    };

    const handleScheduleAddressChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        var addrStr = event.target.value;

        console.log("handleScheduleAddressChange: ", event.target.value);
        var addr;

        if (addrStr && addrStr !== "") addr = addresses.find((addr) => addr.cid === addrStr);

        if (addr) {
            setAddress(addr);
        } else {
            console.log("Address Not Found: ", addr);
            setAddress({} as ScheduleAddress);
        }
        console.log("Cart handleScheduleAddressChange", addr);
    };

    useEffect(() => {
        if (address && address?.ccustno !== "") {
            setAddressLabel("Schedule/Address");
        } else setAddressLabel("Please select Schedule/Shipping address...");
    }, [address]);

    const calculateTotal = (items: WebInventoryItem[]) =>
        items.reduce((acc: number, item) => acc + item.nqty * item.nprice, 0);

    if (loading) return <LoadingSpinner message='Loading...' />;

    return (
        <Stack
            direction={"column"}
            spacing={2}
            sx={{ width: "98%" }}
        >
            <Grid
                container
                width={"100%"}
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                    sm={9}
                    width={"100%"}
                >
                    <div className='float-left'></div>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={3}
                    width={"100%"}
                >
                    <Label className='search_textfields_label'>Schedule/Shipping Address</Label>
                    <SelectField
                        className='search_textfields'
                        labelHidden
                        id='sel-address'
                        onChange={handleScheduleAddressChange}
                        value={address?.cid}
                        label={addressLabel}
                        placeholder='Please select an Address...'
                    >
                        {addresses &&
                            addresses?.map((addr) => (
                                <option
                                    className='search_textfields'
                                    key={addr.cid}
                                    value={addr.cid}
                                >
                                    {addr.caddr}
                                    {/*<div dangerouslySetInnerHTML={{ __html: addr.caddr }} />*/}
                                </option>
                            ))}
                    </SelectField>
                </Grid>
            </Grid>
            <Grid
                width={"100%"}
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                    sm={9}
                >
                    <Box className='component_box'>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <Hidden smDown>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={2}
                                        >
                                            <h6>Product</h6>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                        >
                                            <h6></h6>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                        >
                                            <div className='center-text'>
                                                <h6>Quantity</h6>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                        >
                                            <h6>Price</h6>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <div className='short-divider'>
                                                <CustomDivider />
                                            </div>
                                            <br />
                                        </Grid>
                                    </Grid>
                                </Hidden>
                                {cartItems && cartItems.length ?
                                    <div>
                                        {cartItems.map((item) => (
                                            <CartItem
                                                key={item.citemno}
                                                item={item}
                                                addToCart={addToCart}
                                                removeFromCart={removeFromCart}
                                            />
                                        ))}
                                    </div>
                                :   <div>No items in cart</div>}
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={3}
                >
                    <Box className='component_box'>
                        {/*<h5>Total: ${calculateTotal(cartItems).toFixed(2)}</h5>*/}
                        <CartInfo
                            poNumber={poNumber}
                            setPoNumber={setPoNumber}
                            deliveryInstructions={deliveryInstructions}
                            setDeliveryInstructions={setDeliveryInstructions}
                            paymentType={paymentType}
                            setpaymentType={setPaymentType}
                            ccProfileID={ccProfileID}
                            setCcProfileID={setCcProfileID}
                            ccustno={address && address.ccustno}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={2}
                width={"100%"}
                sx={{ marginLeft: "20px" }}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ width: "100%", marginBottom: "20px" }}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <div className={!isMobile ? "" : "center-text"}>
                        <Button
                            variant='outlined'
                            onClick={keepShopping}
                            sx={{
                                float: !isMobile ? "left" : "none",
                                backgroundColor: "white",
                                color: "black",
                                textTransform: "none",
                                width: "200px",
                                height: "50px",
                                marginRight: "auto",
                                marginLeft: "auto",
                                alignItems: "center",
                            }}
                        >
                            Keep Shopping
                        </Button>
                    </div>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ width: "100%" }}
                    alignItems={"center"}
                >
                    <div className={!isMobile ? "" : "center-text"}>
                        <GreenButton
                            variant='contained'
                            onClick={handleCheckout}
                            sx={{
                                float: !isMobile ? "right" : "none",
                                textTransform: "none",
                                width: "200px",
                                height: "50px",
                                marginRight: "auto",
                                marginLeft: "auto",
                                alignItems: "center",
                            }}
                        >
                            Proceed to Checkout
                        </GreenButton>
                    </div>
                </Grid>
            </Grid>
            <br />
            <br />
            <br />
            <br />
        </Stack>
    );
};

export default Cart;
