import { Box, Button, Divider } from "@mui/material";
import React from "react";
import NavBreadcrumbs from "../helpers/NavBreadcrumbs";
import MakePayments from "./Test";
import PayInvoices from "./ViewOpenInvoices";

const PaymentsPage = () => {
    const [view, setView] = React.useState("payInvoices");

    return (
        <Box sx={{ width: "100%", typography: "body1" }}>
            <NavBreadcrumbs currentView={view} />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                {/*<Button
                    onClick={() => setView("makePayments")}
                    sx={{
                        textTransform: "none",
                        fontFamily: "Montserrat",
                        color: view === "makePayments" ? "#1E2832" : "#DADCE0",
                        fontWeight: view === "makePayments" ? 600 : 500,
                        fontSize: 16,
                        marginRight: 1,
                        opacity: view === "makePayments" ? 1 : 1,
                    }}
                >
                    Make a Payment
                </Button>*/}
                <Button
                    onClick={() => setView("payInvoices")}
                    sx={{
                        textTransform: "none",
                        fontFamily: "Montserrat",
                        color: view === "payInvoices" ? "#1E2832" : "#DADCE0",
                        fontWeight: view === "payInvoices" ? 600 : 500,
                        fontSize: 16,
                        marginLeft: 1,
                        opacity: view === "payInvoices" ? 1 : 1,
                    }}
                >
                    Pay Open Invoices
                </Button>
            </Box>
            <Divider
                variant='middle'
                sx={{
                    mb: 2,
                    borderWidth: 1,
                    borderColor: "divider",
                }}
            />
            {view === "makePayments" && <MakePayments />}
            {view === "payInvoices" && <PayInvoices />}
        </Box>
    );
};

export default PaymentsPage;
