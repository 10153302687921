import "@fontsource/montserrat";
import { Box, Button, IconButton, Stack } from "@mui/material";
import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "../../styles.css";
import { QuantityInput } from "../QuantityInput";
import { Customer, WebInventoryItem } from "../StoreItems";
import SuggestedItems from "../SuggestedItems";
import { Wrapper } from "./Item.styles";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

type Props = {
    item: WebInventoryItem;
    customer: Customer;
    handleAddToCart: (clickedItem: WebInventoryItem) => void;
    handleItemDisplayClose: () => void;
    handleItemDisplay: (clickedItem: WebInventoryItem) => void;
};

const Item: React.FC<Props> = ({
    item,
    customer,
    handleAddToCart,
    handleItemDisplayClose,
    handleItemDisplay,
}) => {
    const [currentPage, setCurrentPage] = React.useState(1);
    const [currentImage, setCurrentImage] = React.useState(
        item.oImages[0] && item.oImages[0].cimgurl ? item.oImage.cimgurl : "",
    );
    const [quantity, setQuantity] = React.useState(1);
    const numberOfImages = item.oImages ? item.oImages.length : 0;

    const handleCurrentImageChange = (imageNumber: number) => {
        if (item.oImages && item.oImages.length >= imageNumber) {
            setCurrentImage(item.oImages[imageNumber - 1].cimgurl);
        }
        setCurrentPage(imageNumber);
    };

    React.useEffect(() => {
        handleCurrentImageChange(1);
    }, [item]);

    React.useEffect(() => {
        console.log("Quantity: ", quantity);
    }, [quantity]);

    /*  const sliderSettings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 9000,
  };
*/
    const images =
        item.oImages &&
        item.oImages.map((image) => {
            return { original: image.cimgurl, thumbnail: image.cimgurl };
        });

    console.log("item.oImages", JSON.stringify(item.oImages));
    return (
        <>
            <Wrapper>
                <br />
                <br />
                {/*<Stack alignItems="center" spacing={2}>
        <h2>Item #: {item.citemno}</h2>
        <img src={currentImage} alt={item.citemno} />
        <Pagination
          size="small"
          count={numberOfImages}
          onChange={(e, value) => handleCurrentImageChange(Number(value))}
          defaultPage={1}
          page={currentPage}
        />
  </Stack>*/}
                {/*<ImageList sx={{ width: 250, height: 350 }} cols={1} rowHeight={164}>
      {item.oImages && item.oImages.map((item) => (
        <ImageListItem key={item.cimgurl}>
          <img
            src={`${item.cimgurl}`}
            alt={item.calt}
            width="164"
            height="164"            
          />
        </ImageListItem>
      ))}
      </ImageList>*/}

                <Stack
                    direction={window.innerWidth > 600 ? "row" : "column"}
                    alignItems={window.innerWidth > 600 ? "left" : "center"}
                    spacing={1}
                >
                    <div className='item_image_slider_wrapper'>
                        <ImageGallery
                            items={images}
                            showPlayButton={false}
                            showFullscreenButton={false}
                            renderLeftNav={(onClick, disabled) => (
                                <IconButton
                                    onClick={onClick}
                                    disabled={disabled}
                                    sx={{
                                        position: "relative",
                                        zIndex: 10,
                                        top: "200px",
                                        left: 0,
                                    }}
                                >
                                    <ArrowCircleLeftOutlinedIcon />
                                </IconButton>
                            )}
                            renderRightNav={(onClick, disabled) => (
                                <IconButton
                                    onClick={onClick}
                                    disabled={disabled}
                                    sx={{ position: "relative", zIndex: 10, top: "200px", right: 0, float: "right" }}
                                >
                                    <ArrowCircleRightOutlinedIcon />
                                </IconButton>
                            )}
                        />
                        {/*<Slider className="item_image_slider" {...sliderSettings}>
          {item.oImages && item.oImages.map((item) => (
              <img className="item_image_slider_img"
                src={`${item.cimgurl}`}
                alt={item.calt}
              />
          ))}  
          </Slider>*/}
                    </div>

                    <div className='item_right_side_block'>
                        <Stack
                            alignItems={window.innerWidth > 600 ? "left" : "center"}
                            direction={"column"}
                            spacing={1}
                        >
                            <div className='item_header_text'>
                                &nbsp; {/*Here for proper spacing*/}
                            </div>
                            <div className='item_header_text'>{item.cdescshort}</div>
                            <div className='item_details_text'>Item #: {item.citemno}</div>
                            {item.bshowpricein && item.bshowpricein === true && (
                                <div className='item_price_text'>${item.nprice.toFixed(2)}</div>
                            )}

                            <Stack
                                spacing={2}
                                direction={window.innerWidth > 600 ? "row" : "column"}
                                alignItems={"left"}
                                justifyItems={"left"}
                            >
                                <div className='item_details_text_quantity_label'>
                                    <b>Quantity:</b>
                                </div>{" "}
                                <QuantityInput
                                    aria-label='Quantity'
                                    min={1}
                                    max={999}
                                    defaultValue={quantity}
                                    onQtyChange={(qty: number) => {
                                        setQuantity(qty);
                                    }}
                                />
                            </Stack>
                            <Stack
                                alignItems='center'
                                spacing={2}
                            >
                                <Stack
                                    alignItems='center'
                                    spacing={2}
                                    direction={window.innerWidth > 600 ? "row" : "column"}
                                >
                                    <Button
                                        className='navbutton'
                                        style={{
                                            backgroundColor: "#58B55D",
                                            height: "60px",
                                            fontFamily: "Montserrat",
                                            fontSize: "16px",
                                            fontWeight: "700",
                                            textTransform: "none",
                                        }}
                                        variant='contained'
                                        disableElevation
                                        onClick={() => {
                                            item.nqty = quantity;
                                            handleAddToCart(item);
                                            handleItemDisplayClose();
                                        }}
                                    >
                                        Add to Cart
                                    </Button>
                                    <Button
                                        className='navbutton'
                                        style={{
                                            backgroundColor: "white",
                                            color: "black",
                                            height: "60px",
                                            fontFamily: "Montserrat",
                                            fontSize: "16px",
                                            fontWeight: "700",
                                            textTransform: "none",
                                        }}
                                        variant='outlined'
                                        disableElevation
                                        onClick={() => handleItemDisplayClose()}
                                    >
                                        Go Back
                                    </Button>
                                </Stack>
                            </Stack>
                            <div className='item_details_text'>
                                <b>Details:</b>
                            </div>
                            <div className='item_details_text'>{item.cdescslong}</div>
                            <br />
                            {item.cdescdetails != "" && (
                                <div
                                    className='item_details_text'
                                    dangerouslySetInnerHTML={{ __html: item.cdescdetails }}
                                ></div>
                            )}
                            <br />
                            <br />
                            <br />
                        </Stack>
                    </div>
                </Stack>
            </Wrapper>

            <div>
                <SuggestedItems
                    customer={customer}
                    citemno={item.citemno}
                    handleAddToCart={handleAddToCart}
                    handleItemDisplay={handleItemDisplay}
                />
            </div>
        </>
    );
};

export default Item;
