import { Box, Button, Grid, Hidden, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    API_AddItemsToSchedule,
    API_Authenticate,
    API_CreateTicket,
    API_GetNextDeliveryDate,
    API_GetScheduleCaseCount,
    API_GetScheduleHeader,
    GetUserName,
    OrderItems,
} from "../services/GetInvoiceData";
import { GreenButton } from "./Buttons";
import CheckoutInfo from "./CheckoutInfo";
import CheckoutItem from "./CheckoutItem";
import CheckoutOrderInfo from "./CheckoutOrderInfo";
import { CustomDivider } from "./CustomDivider";
import LoadingSpinner from "./LoadingSpinner";
import OrderComplete from "./OrderComplete";
import { CheckoutCartItems, ScheduleAddress, WebInventoryItem } from "./StoreItems";
import NavBreadcrumbs from "../helpers/NavBreadcrumbs";

export type CustomerCheckoutInfo = {
    ccompany: string;
    caddr1: string;
    ccity: string;
    cstate: string;
    czip: string;
    cdefaultpaycode: string;
    cdefaultccpaycode: string;
    cschedpaycode: string;
};

export type APIGetCustomerCheckoutInfoReturn = {
    IsSuccessful: boolean;
    Data: CustomerCheckoutInfo;
    ErrorCode: string;
    Error: string;
};

const getCustomerCheckoutInfo = async (ccustno: string, cscono: string, cschedid: string) => {
    return await getCustomerCheckoutInfoAPI(ccustno, cscono, cschedid);
};

const getCustomerCheckoutInfoAPI = async (
    ccustno: string,
    cscono: string,
    cschedid: string,
): Promise<APIGetCustomerCheckoutInfoReturn> => {
    let tok = "";
    const auth = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            UserID: "dgikow",
            AuthKey: "DWJHDKJHWY#%444bghjc((*&^%%%3eewrewooooo**054",
        }),
    };

    tok = await fetch("https://pvsapi.prismvs.com/api/Prism/Authenticate", auth).then((token) => {
        return token.json();
    });
    console.log("token: ", tok);
    console.log("GetCheckoutInfo: ccustno: ", ccustno);
    console.log("GetCheckoutInfo: cscono: ", cscono);
    console.log("GetCheckoutInfo: cschedid: ", cschedid);
    const getcats = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${tok.replaceAll('"', "")}`,
        },
        body: JSON.stringify({
            ccustno: ccustno,
            cscono: cscono,
            cschedid: cschedid,
        }),
    };
    const ret = await fetch(
        "https://pvsapi.prismvs.com/api/Prism/V1/Customer/GetCustomerCheckoutInfo",
        getcats,
    ).then((res) => {
        return res.json();
    });
    console.log("GetCustomerCheckoutInfoAPI ret: ", ret);
    return ret;
};

type Props = {
    cartItems: WebInventoryItem[];
    setCartItems: React.Dispatch<React.SetStateAction<WebInventoryItem[]>>;
    address: ScheduleAddress;
};

const Checkout: React.FC<Props> = ({ cartItems, setCartItems, address }) => {
    const [poNumber, setPoNumber] = React.useState("");
    const [deliveryInstructions, setDeliveryInstructions] = React.useState("");
    const [paymentType, setPaymentType] = React.useState("net");
    const [ccProfileID, setCcProfileID] = React.useState("");
    const [addresses, setAddresses] = React.useState<ScheduleAddress[]>([]);
    const [addressLabel, setAddressLabel] = React.useState<string>("");
    const [checkoutItems, setCheckoutItems] = React.useState([] as CheckoutCartItems[]);
    const [width, setWidth] = React.useState<number>(window.innerWidth);
    const [tax, setTax] = React.useState(0);
    const [totalCases, setTotalCases] = React.useState(0);
    const [customerCheckoutInfo, setCustomerCheckoutInfo] = React.useState(
        {} as CustomerCheckoutInfo,
    );
    const [loading, setLoading] = React.useState(false);
    const [paycode, setPaycode] = React.useState("");
    const [openOrderCompleteDialog, setOpenOrderCompleteDialog] = React.useState(false);
    const [csono, setCsono] = React.useState("");
    const [checkingOut, setCheckingOut] = React.useState(false);
    const [nextDeliveryDate, setNextDeliveryDate] = React.useState("");

    function getFormattedDate(date: Date) {
        if (date < new Date()) return "";

        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");

        return month + "/" + day + "/" + year;
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;
    const navigate = useNavigate();

    async function getNextDeliveryDate(ccustno: string, cschedid: string) {
        try {
            const token = await API_Authenticate();
            const ddate = await API_GetNextDeliveryDate(token, ccustno, cschedid);
            if (ddate?.DeliveryDate) {
                var deliveryDate = new Date(ddate.DeliveryDate);
                if (deliveryDate) setNextDeliveryDate(getFormattedDate(deliveryDate));
            }
        } catch (err) {
            console.log("Error getting next delivery date: ", err);
        }
    }

    function onCheckoutComplete(csono: string) {
        console.log("Checkout complete: ", csono);

        setCartItems([]);
        const key = GetUserName() + "_Cart";
        console.log("Checkout complete:  removing key: ", key);

        setCheckingOut(false);

        localStorage.removeItem(key);

        localStorage.removeItem("poNumber");
        localStorage.removeItem("deliveryInstructions");
        localStorage.removeItem("paymentType");
        localStorage.removeItem("ccProfileID");

        setCsono(csono);
        setOpenOrderCompleteDialog(true);
    }

    const keepShopping = () => {
        navigate("/products");
    };

    const handleCheckout = () => {
        console.log("Cart: checkout: ", cartItems);
        console.log("Cart: address: ", address);
        if (!address || address?.ccustno === "") {
            alert("You need to pick a Schedule/Address before you can checkout");
            return;
        }

        var bChargeCC = 0;

        if (paymentType === "cc") {
            bChargeCC = 1;
            if (!ccProfileID && ccProfileID === "") {
                alert("You need to pick a Credit Card before you can checkout");
                return;
            }
        }
        createTicket(bChargeCC);
    };

    const getCheckOutItems = async () => {
        try {
            console.log("runCheckoutProcess: num of cart items ", cartItems.length);
            console.log("runCheckoutProcess: customer: ", JSON.stringify(address));

            const ordItems = cartItems.map(
                (item) => ({ citemno: item.citemno, nqty: item.nqty }) as OrderItems,
            );

            console.log("runCheckoutProcess:  ordItems: ", ordItems);

            const token = await API_Authenticate();

            const ci = await API_AddItemsToSchedule(
                token,
                address.ccustno,
                address.cscono,
                address.cinvno,
                ordItems,
            );
            if (ci) {
                console.log("checkoutItems: ", JSON.stringify(checkoutItems));
                console.log("checkoutItems: ci: ", ci);
                console.log("checkoutItems: items: ", ci.items);
                cartItems.forEach((item) => {
                    const checkoutItem = ci.items.find(
                        (it: CheckoutCartItems) => it.citemno === item.citemno,
                    );
                    if (checkoutItem) {
                        checkoutItem.image =
                            item.oImage && item.oImage.cimgurl ? item.oImage.cimgurl : "";
                    }
                });
                console.log("checkoutItems: items after adding image: ", ci.items);
                setCheckoutItems(ci.items);
            } else {
                console.log("checkoutItems null");
            }
        } catch (error) {
            console.error("Failed checkout process: ", error);
        } finally {
            console.log("Finished runCheckoutProcess");
        }
    };

    const getTotalPrice = (items: CheckoutCartItems[]) => {
        const total = items.reduce((ack: number, item) => ack + item.nprice * item.nqty, 0);

        return total + tax;
    };

    const createTicket = async (bchargeCC: number = 1) => {
        const ccustno = address.ccustno;
        const cschedid = address.cinvno;
        const cpono = poNumber;
        const username = localStorage.getItem("user");
        const corderby = username ? username : "";
        const cpaycode = bchargeCC ? "CC-AUTH" : paycode;
        const cmessage = deliveryInstructions;

        setCheckingOut(true);

        try {
            const total = getTotalPrice(checkoutItems);

            await getNextDeliveryDate(address.ccustno, address.cinvno);

            const shipDate = nextDeliveryDate ? new Date(nextDeliveryDate) : new Date();
            const deliveryDate = shipDate;

            const token = await API_Authenticate();
            const ret = await API_CreateTicket(
                token,
                ccustno,
                cschedid,
                cpono,
                corderby,
                cpaycode,
                cmessage,
                total,
                bchargeCC,
                ccProfileID,
                shipDate,
                deliveryDate,
            );
            console.log("Checking out: ", ret);
            console.log("Checking out: ", JSON.stringify(ret));
            if (!ret) {
                alert("Error placing order.  Please try again.");
                return;
            }
            if (ret.IsSuccessful === false) {
                alert("Error placing order.  [" + ret.ErrorCode + "] - " + ret.Error);
                return;
            }
            onCheckoutComplete(ret.TicketNumber);
        } catch (error) {
            console.error("Failed checkout process: ", error);
            alert("Error placing order.  Please try again.");
        } finally {
            setCheckingOut(false);
        }
    };

    const calculateTotal = (items: CheckoutCartItems[]) =>
        items.reduce((acc: number, item) => acc + item.nqty * item.nprice, 0);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await getCheckOutItems();
                getCustomerCheckoutInfo(address.ccustno, address.cscono, address.cinvno).then(
                    (data) => {
                        if (data.IsSuccessful === false) {
                            console.log(
                                "getCustomerCheckoutInfo Error: " + data.ErrorCode + " - ",
                                data.Error,
                            );
                            return;
                        }
                        setCustomerCheckoutInfo(data.Data);
                        console.log("useEffect - customerCheckoutInfo: ", data);
                        console.log("useEffect - customerCheckoutInfo.data: ", data.Data);
                    },
                );

                const token = await API_Authenticate();
                const schedHeader = await API_GetScheduleHeader(
                    token,
                    address.ccustno,
                    address.cinvno,
                );
                console.log("useEffect - schedHeader: ", schedHeader);
                console.log("useEffect - schedHeader.data: ", schedHeader.Data);
                if (schedHeader)
                    setTax(
                        schedHeader.Data.ntaxamt1 +
                            schedHeader.Data.ntaxamt2 +
                            schedHeader.Data.ntaxamt3,
                    );
                else console.log("Error getting schedHeader - no data");

                const totalSchedCases = await API_GetScheduleCaseCount(
                    token,
                    address.ccustno,
                    address.cinvno,
                );
                console.log("useEffect - totalSchedcases: ", totalSchedCases);
                if (totalSchedCases) setTotalCases(totalSchedCases.CaseCount);
                else console.log("Error getting totalCases - no data");
            } catch (error) {
                console.error("Failed to load data: ", error);
            } finally {
                setLoading(false);
            }
        };
        try {
            setPoNumber(localStorage.getItem("poNumber") || "");
            setDeliveryInstructions(localStorage.getItem("deliveryInstructions") || "");
            setPaymentType(localStorage.getItem("paymentType") || "net");
            setCcProfileID(localStorage.getItem("ccProfileID") || "");
        } catch (err) {
            console.log(
                "Error getting poNumber, deliveryInstructions, paymentType, ccProfileID: ",
                err,
            );
        }
        fetchData();
    }, [address]);

    useEffect(() => {
        getCheckOutItems();
    }, []);

    useEffect(() => {
        if (!customerCheckoutInfo) {
            console.log("Error getting customerCheckoutInfo: ");
            return;
        }
        if (paymentType === "cc") {
            setPaycode(customerCheckoutInfo.cdefaultccpaycode);
        } else if (customerCheckoutInfo.cschedpaycode !== "")
            setPaycode(customerCheckoutInfo.cschedpaycode);
        else setPaycode(customerCheckoutInfo.cdefaultpaycode);
    }, [paymentType, customerCheckoutInfo]);

    if (checkingOut) return <LoadingSpinner message='Placing order...' />;

    if (loading) return <LoadingSpinner message='Loading...' />;

    return (
                <Box sx={{ width: "100%", typography: "body1" }}>
            <NavBreadcrumbs currentView='checkout' />
        <Stack
            direction={"column"}
            spacing={2}
            sx={{ width: "98%" }}
        >
            <Box sx={{ width: "100vw" }}>{/* This box is here for correct sizing */}</Box>
            <OrderComplete
                csono={csono}
                deliveryDate={nextDeliveryDate}
                openDialog={openOrderCompleteDialog}
            />
            <Grid
                width={"100%"}
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                    sm={9}
                >
                    <Box className='component_box'>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <Hidden smDown>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={2}
                                        >
                                            <h6>Product</h6>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                        >
                                            <h6></h6>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                        >
                                            <div>
                                                <h6>Quantity</h6>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                        >
                                            <h6>Price</h6>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <div className='short-divider'>
                                                <CustomDivider />
                                            </div>
                                            <br />
                                        </Grid>
                                    </Grid>
                                </Hidden>
                                {checkoutItems.length ?
                                    <div>
                                        {checkoutItems.map((item) => (
                                            <CheckoutItem
                                                key={item.citemno}
                                                item={item}
                                            />
                                        ))}
                                    </div>
                                :   <div>No items in cart</div>}
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={3}
                >
                    <Stack
                        spacing={2}
                        direction='column'
                    >
                        <Box className='component_box'>
                            <CheckoutInfo
                                poNumber={poNumber}
                                deliveryInstructions={deliveryInstructions}
                                paymentType={paymentType}
                                ccProfileID={ccProfileID}
                                ccustno={address && address.ccustno}
                                price={calculateTotal(checkoutItems).toFixed(2)}
                                numOfItems={checkoutItems.reduce((acc, item) => acc + item.nqty, 0)}
                                total={(calculateTotal(checkoutItems) + tax).toFixed(2)}
                                tax={tax.toFixed(2)}
                                totalCases={totalCases.toFixed(0)}
                            />
                        </Box>
                        <Box className='component_box'>
                            <CheckoutOrderInfo
                                deliveryInstructions={deliveryInstructions}
                                address={address}
                                paycode={paycode}
                            />
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={2}
                width={"100%"}
                sx={{ marginLeft: "20px" }}
            >
                <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ width: "100%", marginBottom: "20px" }}
                >
                    <div className={!isMobile ? "" : "center-text"}>
                        <Button
                            variant='outlined'
                            onClick={keepShopping}
                            sx={{
                                float: !isMobile ? "left" : "none",
                                backgroundColor: "white",
                                color: "black",
                                textTransform: "none",
                                width: "200px",
                                height: "50px",
                            }}
                        >
                            Keep Shopping
                        </Button>
                    </div>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ width: "100%" }}
                >
                    <div className={!isMobile ? "" : "center-text"}>
                        <GreenButton
                            variant='contained'
                            onClick={handleCheckout}
                            sx={{
                                float: !isMobile ? "right" : "none",
                                textTransform: "none",
                                width: "200px",
                                height: "50px",
                            }}
                        >
                            Place Your Order
                        </GreenButton>
                    </div>
                </Grid>
            </Grid>
            <br />
            <br />
            <br />
            <br />
        </Stack>
        </Box>
    );
};

export default Checkout;
