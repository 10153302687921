import { Label, SelectField, TextAreaField, TextField } from "@aws-amplify/ui-react";
import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, styled } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PaymentMethod } from "../../interfaces/InvoiceInterfaces";
import { API_Authenticate, API_GetPaymentMethods } from "../../services/GetInvoiceData";
import { GreenButton } from "../Buttons";
import { CustomDivider } from "../CustomDivider";

type Props = {
    poNumber: string;
    setPoNumber: React.Dispatch<React.SetStateAction<string>>;
    deliveryInstructions: string;
    setDeliveryInstructions: React.Dispatch<React.SetStateAction<string>>;
    paymentType: string;
    setpaymentType: React.Dispatch<React.SetStateAction<string>>;
    ccProfileID: string;
    setCcProfileID: React.Dispatch<React.SetStateAction<string>>;
    ccustno: string;
};

const StyledRadio = styled(Radio)({
    "&.MuiRadio-root": {
        color: "#58B55D;",
        padding: "1px",
    },
    "&.MuiRadio-colorSecondary": {
        "&.Mui-checked": {
            color: "#58B55D;",
            padding: "1px",
        },
    },
});

const CartInfo: React.FC<Props> = ({
    poNumber,
    setPoNumber,
    deliveryInstructions,
    setDeliveryInstructions,
    paymentType,
    setpaymentType,
    ccProfileID,
    setCcProfileID,
    ccustno,
}) => {
    const [allowCreditCardPayments, setAllowCreditCardPayments] = React.useState(true);
    const [refreshPaymentType, setRefreshPaymentType] = React.useState(0);
    const [editingCreditCards, setEditingCreditCards] = React.useState(false);
    const [paymentMethods, setPaymentMethods] = React.useState<PaymentMethod[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            if (paymentType === "cc" && paymentMethods && paymentMethods.length === 0) {
                const token = await API_Authenticate();

                console.log("Getting payment methods for ccustno: ", ccustno);
                const paymentMethodData = await API_GetPaymentMethods(ccustno, token);
                console.log("payment methods: ", paymentMethodData);
                if (paymentMethodData && paymentMethodData.PaymentProfiles) {
                    for (var i = 0; i < paymentMethodData.PaymentProfiles.length; i++) {
                        paymentMethodData.PaymentProfiles[i].nprofid = BigInt(
                            paymentMethodData.PaymentProfiles[i].payment_profile_id,
                        );
                        paymentMethodData.PaymentProfiles[i].ncustprfid = BigInt(
                            paymentMethodData.PaymentProfiles[i].cust_profile_id,
                        );
                    }
                    setPaymentMethods(paymentMethodData?.PaymentProfiles);
                }
            }
        };

        fetchData();
    }, [paymentType, refreshPaymentType]);

    const modifyCreditCards = () => {
        navigate("/paymethods", { state: { ccustno: ccustno } });
    };

    return (
        <Stack
            spacing={2}
            direction='column'
        >
            <div>
                <Label className='search_textfields_label'>PO Number</Label>
                <TextField
                    label='Search'
                    labelHidden
                    className='search_textfields'
                    onChange={(e) => {
                        setPoNumber(e.target.value);
                    }}
                    value={poNumber}
                />
            </div>

            <div>
                <Label className='search_textfields_label'>Delivery Instructions</Label>
                <TextAreaField
                    label='Search'
                    labelHidden
                    className='deliveryinstructions_textfield'
                    onChange={(e) => {
                        setDeliveryInstructions(e.target.value);
                    }}
                    value={deliveryInstructions}
                />
            </div>

            {allowCreditCardPayments ?
                <div>
                    <div>
                        <Label className='search_textfields_label'>Select Payment Method</Label>
                        <div className='short-divider'>
                            <CustomDivider />
                        </div>
                    </div>
                    <button
                        className='filters_clearall'
                        onClick={() => {}}
                    >
                        Terms and Conditions
                    </button>

                    <FormControl>
                        <RadioGroup
                            sx={{ m: 2 }}
                            aria-labelledby='demo-radio-buttons-group-label'
                            value={paymentType}
                            onChange={(e) => setpaymentType(e.target.value)}
                            name='radio-buttons-group'
                        >
                            <FormControlLabel
                                value='net'
                                control={<StyledRadio />}
                                label='Standard Payment Terms'
                                className='search_textfields'
                            />
                            <FormControlLabel
                                value='cc'
                                control={<StyledRadio />}
                                label='Credit Card'
                                className='search_textfields'
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
            :   null}

            <br />

            {paymentType === "cc" ?
                <>
                    <div>
                        <div id='paymentTypes'>
                            <Label className='search_textfields_label'>Select a Credit Card</Label>
                            <SelectField
                                className='search_textfields'
                                labelHidden
                                id='sel-cc'
                                onChange={(e: any) => {
                                    setCcProfileID(e.target.value);
                                }}
                                value={ccProfileID}
                                label='Credit Card'
                                placeholder='Please select a credit card...'
                            >
                                {paymentMethods &&
                                    paymentMethods.map((method) => (
                                        <option
                                            key={method.nprofid.toString()}
                                            value={method.nprofid.toString()}
                                        >
                                            CC: {method.ccardnum} Exp: {method.cexpdate} Name:{" "}
                                            {method.cfirstname + " " + method.clastname}{" "}
                                            {method.ldefault === 1 ? "(Default)" : ""}
                                        </option>
                                    ))}
                            </SelectField>
                            <br />
                            <Stack alignItems={"right"}>
                                <GreenButton
                                    variant='contained'
                                    onClick={() => {
                                        modifyCreditCards();
                                    }}
                                >
                                    Modify Credit Cards
                                </GreenButton>
                            </Stack>
                        </div>
                    </div>
                    <br />
                </>
            :   null}
        </Stack>
    );
};

export default CartInfo;
