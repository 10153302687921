import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Grid, Hidden, IconButton } from "@mui/material";
import React from "react";
import { QuantityInput } from "../QuantityInput";
import { WebInventoryItem } from "../StoreItems";
import { Wrapper } from "./CartItem.styles";

type Props = {
    item: WebInventoryItem;
    addToCart: (clickedItem: WebInventoryItem) => void;
    removeFromCart: (citemno: string, nqty?: number, bAll?: boolean) => void;
};

const CartItem: React.FC<Props> = ({ item, addToCart, removeFromCart }) => {
    function getNewItem(item: WebInventoryItem, qty: number) {
        return { ...item, nqty: qty };
    }
    const updateQuantity = (item: WebInventoryItem, qty: number, bAll?: boolean) => {
        if (qty > item.nqty) {
            addToCart(getNewItem(item, qty));
        } else {
            var qtyToRemove = item.nqty - qty;

            removeFromCart(item.citemno, qtyToRemove, bAll);
        }
    };

    return (
        <Wrapper>
            <Grid
                container
                spacing={2}
            >
                <Hidden smDown>
                    <Grid
                        item
                        xs={6}
                        sm={2}
                    >
                        <div className='image'>
                            <img
                                src={item.oImage && item.oImage.cimgurl ? item.oImage.cimgurl : ""}
                                alt={item.citemno}
                            />
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={3}
                    >
                        {item.citemno + " - " + item.cdescshort}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={3}
                    >
                        <QuantityInput
                            defaultValue={item.nqty}
                            onQtyChange={(qty) => updateQuantity(item, qty)}
                            min={1}
                            max={9999}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={3}
                    >
                        ${item.nprice.toFixed(2)}
                    </Grid>
                    <Grid
                        item
                        xs={1}
                    >
                        <IconButton onClick={() => updateQuantity(item, item.nqty, true)}>
                            <DeleteForeverIcon />
                        </IconButton>
                    </Grid>
                </Hidden>
                <Hidden smUp>
                    <Grid
                        item
                        xs={6}
                    >
                        <div className='image'>
                            <img
                                src={item.oImage && item.oImage.cimgurl ? item.oImage.cimgurl : ""}
                                alt={item.citemno}
                            />
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        {item.citemno + " - " + item.cdescshort}
                        <br />
                        <b>${item.nprice.toFixed(2)}</b>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <QuantityInput
                            defaultValue={item.nqty}
                            onQtyChange={(qty) => updateQuantity(item, qty)}
                            min={1}
                            max={9999}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <IconButton onClick={() => updateQuantity(item, item.nqty, true)}>
                            <DeleteForeverIcon />
                        </IconButton>
                    </Grid>
                </Hidden>
            </Grid>
        </Wrapper>
    );
};

export default CartItem;
