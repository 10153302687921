import {
    React,
    Flex,
    Text,
    Divider,
    Link,
    View,
    Button,
    Grid,
    Card,
    Box,
} from "../identity/helpers/imports";
import { GetTenantInfo } from "services/GetInvoiceData";
import { useHubState } from "../context/HubContext";
import { useIsMobile } from "../helpers/CustomHooks";

const Footer = () => {
    const { hubState } = useHubState();
    const isMobile = useIsMobile();

    const [state, setState] = React.useState({
        companyName: "Prism Visual Software",
        companyLink: "https://www.prismvs.com/",
        address1: "123 Main Street",
        address2: "",
        city: "Anytown",
        stateAbrv: "CA",
        zip: "12345",
        phone: "(123) 456-7890",
        email: "prism@prismvs.com",
        tenant: {} as any,
    });

    const { companyName, address1, address2, city, stateAbrv, zip, phone, email } = state;

    function updateTenantInfo() {
        GetTenantInfo().then((tenant: any) => {
            setState((prevState) => ({ ...prevState, companyName: tenant.companyname }));
            setState((prevState) => ({ ...prevState, address1: tenant.address1 }));
            setState((prevState) => ({ ...prevState, address2: tenant.address2 }));
            setState((prevState) => ({ ...prevState, city: tenant.city }));
            setState((prevState) => ({ ...prevState, stateAbrv: tenant.state }));
            setState((prevState) => ({ ...prevState, zip: tenant.zip }));
            setState((prevState) => ({ ...prevState, phone: tenant.phone }));
            setState((prevState) => ({ ...prevState, phone: tenant.phone }));
            setState((prevState) => ({ ...prevState, email: tenant.email }));
        });
    }

    React.useEffect(() => {
        updateTenantInfo();
    }, []);

    React.useEffect(() => {
        updateTenantInfo();
        console.log("Footer: hubState", hubState);
        console.log({ state }, "state");
    }, [hubState]);

    const getYear = () => {
        return new Date().getFullYear();
    };

    return (
        <Box sx={{ width: "100vw", height: "auto", marginTop: "20px" }}>
            <Grid
                width='100%'
                height={address2 && address2?.trim() !== "" ? "110px" : "80px"}
                backgroundColor='hsla(210, 25%, 16%, 1)'
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                padding='0 20px'
                color='#ffffff'
            >
                <Card backgroundColor='transparent'>
                    <Text color='#ffffff'>{companyName}</Text>
                    <Text color='#ffffff'>
                        {address1 && address1?.trim() !== "" && <>{address1}</>}
                    </Text>
                    <Text color='#ffffff'>
                        {address2 && address2?.trim() !== "" && <>{address2}</>}
                    </Text>
                    <Text color='#ffffff'>
                        {city} {stateAbrv} {zip}
                    </Text>
                </Card>
                {!isMobile && (
                    <Card backgroundColor='transparent'>
                        <Link
                            href='https://www.prismvs.com/'
                            target='_blank'
                            rel='noopener noreferrrer'
                            color='#ffffff'
                        >
                            Prism Visual Software, Inc {getYear()}. All Rights Reserved. Powered by
                            ServQuest ©
                        </Link>
                    </Card>
                )}
                <Card
                    backgroundColor='transparent'
                    color='#ffffff'
                >
                    <Text color='#ffffff'>{phone !== "" && <>{phone}</>}</Text>
                    <Text color='#ffffff'>
                        {email !== "" && (
                            <Link
                                href={"mailto:" + email}
                                target='_blank'
                                rel='noopener noreferrrer'
                                color='#ffffff'
                            >
                                {email}
                            </Link>
                        )}
                    </Text>
                </Card>
            </Grid>
            {isMobile && (
                <Grid
                    width='100%'
                    backgroundColor='hsla(210, 25%, 16%, 1)'
                    display='flex'
                    alignItems='center'
                    textAlign='center'
                    justifyContent='center'
                    color='#ffffff'
                >
                    <Card backgroundColor='transparent'>
                        <Link
                            href='https://www.prismvs.com/'
                            target='_blank'
                            rel='noopener noreferrrer'
                            color='#ffffff'
                        >
                            Prism Visual Software, Inc {getYear()}. All Rights Reserved. Powered by
                            ServQuest ©
                        </Link>
                    </Card>
                </Grid>
            )}
        </Box>
    );
};

export default Footer;
