import { FieldGroupIconButton, Flex, Label, SelectField, TextField } from "@aws-amplify/ui-react";
import { Button, Container, Dialog, Pagination } from "@mui/material";
import { Grid } from "@mui/material/";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Hidden from "@mui/material/Hidden";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled as muistyled } from "@mui/material/styles";
import React, { ChangeEvent, useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";
import {
    API_AddItemsToSchedule,
    API_Authenticate,
    API_GetNextDeliveryDate,
    API_GetScheduleAddresses,
    API_GetWebCategories,
    API_GetWebItems,
    API_GetSpecialImages,
    GetSetting,
    GetUserName,
    OrderItems,
    API_GetWebItem,
} from "../services/GetInvoiceData";
import "../styles.css";
import Item from "./Item/Item";
import ProductCard2 from "./Item/ProductCard";
import LoadingSpinner from "./LoadingSpinner";
import { useIsMobile, useIsLessThanFullWidth } from "helpers/CustomHooks";
import theme from "theme/theme";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export type Customer = {
    ccustno: string;
    cscono: string;
    cschedid: string;
};

export type CheckoutCartItems = {
    citemno: string;
    cdescript: string;
    cbarcode1: string;
    cmeasure: string;
    cclass: string;
    cprodline: string;
    nprice: number;
    nqty: number;
    image: string;
};

export type CartItemType = {
    id: number;
    title: string;
    price: number;
    category: string;
    description: string;
    image: string;
    createdAt: string;
    amount: number;
};

export type ItemImage = {
    calt: string;
    ctitle: string;
    cfilename: string;
    cfullfilename: string;
    norder: number;
    bdefault: boolean;
    cimgurl: string;
};

export type WebInventoryItem = {
    citemno: string;
    cdescshort: string;
    cdescslong: string;
    cdescspromo: string;
    cdescdetails: string;
    ccatno: string;
    oImage: ItemImage;
    oImages: ItemImage[];
    nprice: number;
    bincart: boolean;
    nqty: number;
    beditable: boolean;
    bshowpricelout: boolean;
    bshowpricein: boolean;
};

export type APIGetWebItemsReturn = {
    Items: WebInventoryItem[];
    TotalItems: number;
    IsSuccessful: boolean;
};

export type APIGetCategoriesReturn = {
    Categories: Category[];
    IsSuccessful: boolean;
};

export type APIGetScheduleAddressesReturn = {
    Addresses: ScheduleAddress[];
    IsSuccessful: boolean;
    ErrorCode: number;
    Error: string;
};

export type Category = {
    ccatno: string;
    cdescript: string;
    cparentno: string;
};

export type ScheduleAddress = {
    cid: string;
    ccustno: string;
    cscono: string;
    cinvno: string;
    caddr: string;
};

export const SearchWrapper = styled.div`
    width: 100%;
    align-items: center;
    max-width: 800px;
`;

export const Wrapper = muistyled("div")(({ theme }) => ({
    padding: "0",
    maxWidth: "100vw",
    xl: {
        maxWidth: "1190px",
    },
}));

const getScheduleAddress = async (username: string) => {
    const token = await API_Authenticate();
    const addrs = await API_GetScheduleAddresses(token, username, "D");
    if (!addrs) {
        console.log("Error getting schedule addresses. (null)");
        return [];
    }
    if (!addrs.IsSuccessful) {
        console.log(
            "Error getting schedule addresses. (" + addrs.ErrorCode + ": " + addrs.Error + ")",
        );
        return [];
    }
    return addrs.Addresses;
    /*const addr: ScheduleAddress[] = [
    {
      ccustno: "01-000309",
      cscono: "SAME",
      cinvno: "D",
      caddr1: "1 Main St.",
      ccity: "Port Washington",
      cstate: "NY",
      czip: "11050",
    },
    {
      ccustno: "01-000305",
      cscono: "SAME",
      cinvno: "D",
      caddr1: "1 New St.",
      ccity: "Port Washington",
      cstate: "NY",
      czip: "11050",
    },
  ];

  return addr;*/
};

/*export const Wrapper = styled.div`

`;*/

/*const getProducts = (): CartItemType[] => {
  return Products;
};

const getCategories = (): Category[] => {
  return Categories;
};*/

type Props = {
    selectedCategory: string[];
    selectedFilter: string;
    categories: Category[];
    setCategories: React.Dispatch<React.SetStateAction<Category[]>>;
    setNavBarSearchCategory: (searchCategory: string[]) => void;
    cartOpen: boolean;
    setCartOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setTotalItems: React.Dispatch<React.SetStateAction<number>>;
    setSelectedFilter: React.Dispatch<React.SetStateAction<string>>;
    setSelectedCategory: React.Dispatch<React.SetStateAction<string[]>>;
    onFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onCategoryChange: (event: React.SyntheticEvent, nodeId: string[]) => void;
    setSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    applyFilters: boolean;
    setCartItems: React.Dispatch<React.SetStateAction<WebInventoryItem[]>>;
    cartItems: WebInventoryItem[];
    addItemToCart: (clickedItem: WebInventoryItem) => void;
    removeFromCart: (citemno: string, nqty?: number, bAll?: boolean) => void;
    runCheckout?: boolean;
    currentAddress?: ScheduleAddress;
    setCurrentAddress?: React.Dispatch<React.SetStateAction<ScheduleAddress>>;
    setShowItem: React.Dispatch<React.SetStateAction<boolean>>;
    showItem: boolean;
    refreshCartItems: boolean;
};

type State = {
    products: CartItemType[];
    cartOpen: boolean;
    cartItems: CartItemType[];
    numberOfItems: number;
    currentPage: number;
    ItemsPerPage: number;
    pageNumbers: number[];
    numberOfPages: number;
};

const DefaultCustomer: Customer = {
    ccustno: "",
    cscono: "",
    cschedid: "",
};

const testCustomer: Customer = {
    ccustno: "",
    cscono: "",
    cschedid: "",
};

//const ProductCard: React.FC<Props> = ({ item, handleAddToCart }) => (

const StoreItems: React.FC<Props> = ({
    selectedCategory,
    selectedFilter,
    categories,
    setCategories,
    setNavBarSearchCategory,
    cartOpen,
    setCartOpen,
    setTotalItems,
    setSelectedFilter,
    setSelectedCategory,
    onFilterChange,
    onCategoryChange,
    setSideBarOpen,
    applyFilters,
    setCartItems,
    cartItems,
    addItemToCart,
    removeFromCart,
    runCheckout,
    currentAddress,
    setCurrentAddress,
    setShowItem,
    showItem,
    refreshCartItems,
}) => {
    //const [cartOpen, setCartOpen] = useState(false);
    const [products, setProducts] = useState([] as WebInventoryItem[]);
    const [currentPage, setCurrentPage] = useState(1);
    const [ItemsPerPage, setItemsPerPage] = useState(12);
    const [pageNumbers, setPageNumbers] = useState([] as number[]);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [searchInput, setSearchInput] = useState("");
    const [searchString, setSearchString] = useState("");
    const [inSearch, setInSearch] = useState(false);
    const [searchCategory, setSearchCategory] = useState(["ALL"]);
    const [checkout, setCheckout] = useState(false);
    const [searchFilter, setSearchFilter] = useState("0");
    const [apiProducts, setApiProducts] = useState([] as WebInventoryItem[]);
    const [customer, setCustomer] = useState(DefaultCustomer);
    const [itemToDisplay, setItemToDisplay] = useState({} as WebInventoryItem);
    const [addresses, setAddresses] = React.useState([] as ScheduleAddress[]);
    const [selectedAddress, setSelectedAddress] = React.useState("");
    const [addressLabel, setAddressLabel] = React.useState(
        "Please select Schedule/Shipping address...",
    );
    const [startCheckout, setStartCheckout] = React.useState(runCheckout ? runCheckout : false);
    const [checkoutItems, setCheckoutItems] = React.useState([] as CheckoutCartItems[]);
    const [checkoutComplete, setCheckoutComplete] = React.useState(false);
    const [createdTicket, setCreatedTicket] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [nextDeliveryDate, setNextDeliveryDate] = React.useState("");
    const [showNextDeliveryDate, setShowNextDeliveryDate] = React.useState(false);
    const [sort, setSort] = React.useState("");
    const [showFilters, setShowFilters] = React.useState(false);
    const [specialImages, setSpecialImages] = React.useState([] as any);

    const isMobile = useIsMobile();
    const isLessThanFullWidth = useIsLessThanFullWidth();

    const getAddressFromCID = (cid: string) => {
        console.log("getAddressFromCID: ", cid);
        const address = addresses.find((address) => address.cid === cid);
        if (address) {
            console.log("getAddressFromCID: Found: ", address);
            return address;
        }
        console.log("getAddressFromCID: Address Not Found");
        return {} as ScheduleAddress;
    };

    const handleScheduleAddressChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        var cust: Customer = DefaultCustomer;
        var addr = event.target.value;

        console.log("handleScheduleAddressChange: ", event.target.value);

        if (addr && addr !== "") cust = getCustomerFromAddress(event.target.value);

        if (cust && cust.ccustno && cust.ccustno !== "") {
            setSelectedAddress(addr);
            setCustomer(cust);
        } else {
            console.log("Customer Not Found: ", addr);
            setSelectedAddress("");
            setCustomer(DefaultCustomer);
        }
        console.log("MainStore handleScheduleAddressChange", addr);
    };

    const getCustomerFromAddress = (addr: string) => {
        const cust = addr.split("|");

        return { ccustno: cust[0], cscono: cust[1], cschedid: cust[2] };
    };

    const getNumberOfPages = async (itemsPerPage: number) => {
        const numOfPages = Math.ceil((await getFilteredProducts().length) / itemsPerPage);
        await setNumberOfPages(numOfPages);

        console.log("getNumberOfPages numberOfPages: ", numOfPages);
    };

    const handleItemsPerPageChange = (event: SelectChangeEvent) => {
        console.log("Items Per Page Changed: ", event.target.value);
        setCurrentPage(1);
        setItemsPerPage(Number(event.target.value));
        getNumberOfPages(Number(event.target.value));
    };

    const handleClickPagination = (event: React.MouseEvent<HTMLLIElement>) => {
        console.log("Page click: ", event.currentTarget.id);
        setCurrentPage(Number(event.currentTarget.id));
        //setDisplayPageNumbersFromPageNumbers(Number(event.currentTarget.id));
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    };

    const getTotalItems = (items: WebInventoryItem[]) =>
        items.reduce((acc, item) => acc + item.nqty, 0);

    /* const addItemToCart = (clickedItem: WebInventoryItem) => {
    const prev = cartItems;
    const isItemInCart = prev.find(
      (item) => item.citemno === clickedItem.citemno,
    );

    if(clickedItem.nqty === 0)
      clickedItem.nqty = 1;

    if (isItemInCart) {
      return prev.map((item) =>
        item.citemno === clickedItem.citemno
          ? { ...item, nqty: item.nqty + clickedItem.nqty }
          : item,
      );
    }
    return [...prev, { ...clickedItem }];
  };*/

    const runCheckoutProcess = async () => {
        try {
            console.log("runCheckoutProcess: num of cart items ", cartItems.length);
            console.log("runCheckoutProcess: customer: ", JSON.stringify(customer));

            const ordItems = cartItems.map(
                (item) => ({ citemno: item.citemno, nqty: item.nqty }) as OrderItems,
            );

            console.log("runCheckoutProcess:  ordItems: ", ordItems);

            const token = await API_Authenticate();

            const ci = await API_AddItemsToSchedule(
                token,
                customer.ccustno,
                customer.cscono,
                customer.cschedid,
                ordItems,
            );
            if (ci) {
                console.log("checkoutItems: ", JSON.stringify(checkoutItems));
                console.log("checkoutItems: ci: ", ci);
                console.log("checkoutItems: items: ", ci.items);
                cartItems.forEach((item) => {
                    const checkoutItem = ci.items.find(
                        (it: CheckoutCartItems) => it.citemno === item.citemno,
                    );
                    if (checkoutItem) {
                        checkoutItem.image =
                            item.oImage && item.oImage.cimgurl ? item.oImage.cimgurl : "";
                    }
                });
                console.log("checkoutItems: items after adding image: ", ci.items);
                setCheckoutItems(ci.items);
            } else {
                console.log("checkoutItems null");
            }
            /*const paymentMethodData = await API_GetPaymentMethods("customerNumber", token);
      if (paymentMethodData) {
        setPaymentMethods(paymentMethodData);
      }*/
            setCartOpen(false);
            setCheckout(true);
        } catch (error) {
            console.error("Failed checkout process: ", error);
        } finally {
            console.log("Finished runCheckoutProcess");
            setStartCheckout(false);
        }
    };

    const onCheckoutComplete = (csono: string) => {
        setStartCheckout(false);
        setCheckout(false);
        setCartOpen(false);
        setCreatedTicket(csono);
        setCheckoutComplete(true);
        setCartItems([] as WebInventoryItem[]);
    };

    const goBackToStore = () => {
        setStartCheckout(false);
        setCheckout(false);
        setCartOpen(false);
        setCreatedTicket("");
        setCheckoutComplete(false);
        setCartItems([] as WebInventoryItem[]);
    };

    const handleCheckout = () => {
        if (!selectedAddress || selectedAddress == "") {
            alert("You need to pick a Schedule/Address before you can checkout.");
            return;
        }
        setCustomer(getCustomerFromAddress(selectedAddress));

        setStartCheckout(true);
    };

    const handleCancelCheckout = () => {
        setCartOpen(false);
        setCheckout(false);
    };

    const handleItemDisplay = (clickedItem: WebInventoryItem) => {
        console.log("handleItemDisplay: ", clickedItem);
        setItemToDisplay(clickedItem);
        setShowItem(true);
    };

    async function handleSpecialItemDisplay(item: any) {
        console.log("handleSpecialItemDisplay: ", item);
        if (item.ctitle.startsWith("http://") || item.ctitle.startsWith("https://")) {
            console.log("handleSpecialItemDisplay: link: ", item.ctitle);
            window.open(item.ctitle);
            return;
        }
        if (item.ctitle.startsWith("Category:")) {
            var categories = item.ctitle.replace("Category:", "").trim().split("||");
            console.log("handleSpecialItemDisplay: Categories: ", categories);
            setSelectedCategory(categories);
            return;
        }

        const token = await API_Authenticate();
        const items = await API_GetWebItem(token, GetUserName(), item.ctitle);
        console.log("handleSpecialItemDisplay: items:", items);
        if (items?.Items && items.Items.length > 0) {
            console.log("handleSpecialItemDisplay: Showing item:", items.Items[0]);
            handleItemDisplay(items.Items[0]);
        }
    }

    const handleItemDisplayClose = () => {
        setShowItem(false);
    };

    const handleAddToCart = (clickedItem: WebInventoryItem) => {
        const newCartItems = addItemToCart(clickedItem);
        console.log("Updated Cart: ", JSON.stringify(newCartItems));
        //setCartItems(newCartItems);
    };

    /*const removeFromCart = (citemno: string): WebInventoryItem[] => {
    console.log("removeFromCart: ", citemno);
    let returnCart = cartItems;
    //let returnCart = prev;
    returnCart.reduce((acc, item) => {
      if (item.citemno === citemno) {
        if (item.nqty === 1) {
          returnCart = acc;
          return returnCart;
        }
        console.log("removeFromCart item.amount: ", item.nqty);
        returnCart = [...acc, { ...item, nqty: item.nqty - 1 }];
        return returnCart;
      } else {
        returnCart = [...acc, item];
        return returnCart;
      }
    }, [] as WebInventoryItem[]);

    return returnCart;
  };*/

    const getUpdatedPrice = (citemno: string, origPrice: number) => {
        if (!apiProducts) {
            return origPrice;
        }
        const prod = apiProducts.find((x) => x.citemno === citemno);
        if (prod) {
            return prod.nprice;
        }

        return origPrice;
    };

    const getUpdatedPriceCartItems = (): WebInventoryItem[] => {
        let updatedCart = cartItems;

        updatedCart.reduce((acc, item) => {
            updatedCart = [...acc, { ...item, nprice: getUpdatedPrice(item.citemno, item.nprice) }];
            return updatedCart;
        }, [] as WebInventoryItem[]);

        return updatedCart;
    };

    /*const handleRemoveFromCart = (citemno: string) => {
    const newCartItems = removeFromCart(citemno);
    console.log("Updated Cart: ", JSON.stringify(newCartItems));
    setCartItems(newCartItems);
    if(newCartItems.length == 0)
    {
      const key = GetUserName() + "_Cart";
      console.log("Removing Saved Cart", key);
      localStorage.removeItem(key);
    }
  };*/

    const handleStoreSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setSearchInput(e.target.value);
    };

    const handleStoreCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        //setSearchCategory(e.target.value);
    };
    const getChildCategories = (category: string[]) => {
        if (category.some((x) => x === "ALL") || !categories) {
            return [];
        }

        return (
            categories &&
            categories
                .filter(
                    (cat) =>
                        category.some((x) => x === cat.cparentno) ||
                        category.some((x) => x === cat.ccatno),
                )
                .map((cat) => cat.cdescript)
        );
    };

    /*const getFilteredProducts2 = () => {
    const searchCategories: string[] = getChildCategories(searchCategory);

    if (inSearch) {
      return products.filter(
        (product) =>
          (searchCategory === "ALL" ||
            searchCategories.includes(product.category)) &&
          (product.title.toLowerCase().includes(searchString.toLowerCase()) ||
            product.description
              .toLowerCase()
              .includes(searchString.toLowerCase())),
      );
    }
    if (searchCategory !== "ALL") {
      return products.filter((product) =>
        searchCategories.includes(product.category),
      );
    }

    return products;
  };*/

    const getFilteredProducts = () => {
        const searchCategories: string[] = getChildCategories(searchCategory);

        if (inSearch) {
            return apiProducts.filter(
                (product) =>
                    (searchCategories.some((x) => x === "ALL") ||
                        searchCategories.includes(product.ccatno)) &&
                    (product.citemno.toLowerCase().includes(searchString.toLowerCase()) ||
                        product.cdescshort.toLowerCase().includes(searchString.toLowerCase())),
            );
        }
        if (searchCategories.some((x) => x === "ALL")) {
            return apiProducts.filter((product) => searchCategories.includes(product.ccatno));
        }

        return apiProducts;
    };

    const startSearch = async () => {
        if (searchInput === "") await setInSearch(false);
        else await setInSearch(true);

        await setSearchString(searchInput);
        setNavBarSearchCategory(searchCategory);
        //getNumberOfPages(ItemsPerPage);
    };

    const cancelSearch = async () => {
        // setSearchInput("");
        await setInSearch(false);
        await setSearchInput("");
        await setSearchString("");
        //await setSearchCategory(["ALL"]);
    };

    /*  useEffect(() => {
    console.log("useEffectsearchString: ", searchString);
    getNumberOfPages(ItemsPerPage);
  }, [searchString]);


  useEffect(() => {
    setSearchFilter(selectedFilter);
    getNumberOfPages(ItemsPerPage);
    console.log("selectedFilter: ", selectedFilter);
  }, [selectedFilter]);
*/

    useEffect(() => {
        if (startCheckout) runCheckoutProcess();
        console.log("selectedCategory: ", selectedCategory);
    }, [startCheckout]);

    const getAPIProducts = async () => {
        try {
            setIsLoading(true);
            console.log("getAPIProducts customer: ", customer);
            console.log("getAPIProducts searchCategory: ", searchCategory);
            console.log("getAPIProducts searchFilter: ", searchFilter);
            console.log("getAPIProducts currentPage: ", currentPage);
            console.log("getAPIProducts searchString: ", searchString);

            const token = await API_Authenticate();
            const apiItems = await API_GetWebItems(
                token,
                GetUserName(),
                customer.ccustno,
                customer.cscono,
                customer.cschedid,
                selectedCategory || ["ALL"],
                selectedFilter,
                currentPage,
                ItemsPerPage,
                searchString,
                cartItems.map((item: any) => item.citemno),
                sort,
            );
            console.log("getAPIProducts apiItems: ", apiItems);
            console.log("getAPIProducts Total Items: ", apiItems.TotalItems);
            setNumberOfPages(Math.ceil(apiItems.TotalItems / ItemsPerPage));
            setApiProducts(apiItems.Items);
        } catch (error) {
            console.log("Error loading items: ", error);
        } finally {
            setIsLoading(false);
        }
        //setCategories(getCategories());
    };

    useEffect(() => {
        console.log("StoreItems: Selected Filter: ", selectedFilter);
        console.log("StoreItems: Apply Filter: ", applyFilters);

        if (!isLoading) getAPIProducts();
    }, [currentPage, searchString, ItemsPerPage, applyFilters]);

    useEffect(() => {
        getAPIProducts();
    }, [customer]);

    useEffect(() => {
        console.log("StoreItems: Selected Filter: ", selectedFilter);
        console.log("StoreItems: Apply Filter: ", applyFilters);

        if (applyFilters && !isLoading) getAPIProducts();
    }, [searchCategory, selectedFilter, selectedCategory, sort, applyFilters]);

    useEffect(() => {
        console.log("StoreItems on mount: Selected Filter: ", selectedFilter);
        console.log("StoreItems on mount: Apply Filter: ", applyFilters);

        if (!isLoading) getAPIProducts();
    }, []);

    function getFormattedDate(date: Date) {
        if (date < new Date()) return "";

        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");

        return month + "/" + day + "/" + year;
    }

    async function getSpecialImages() {
        const token = await API_Authenticate();
        const specialImages = await API_GetSpecialImages(token);
        console.log("specialImages: ", specialImages);
        return specialImages;
    }

    useEffect(() => {
        const getMyCategories = async () => {
            const token = await API_Authenticate();
            const mcategories = await API_GetWebCategories(token, GetUserName());
            if (mcategories && mcategories.IsSuccessful) {
                console.log("getMyCategories: ", mcategories.Categories);
                var categories = mcategories.Categories;

                var hasAll: boolean = categories.some((c: Category) => c.ccatno === "ALL");

                categories.map((c: Category) => {
                    if (!hasAll && c.cparentno === "") c.cparentno = "ALL";
                });

                if (!hasAll) categories.push({ ccatno: "ALL", cdescript: "ALL", cparentno: "" });

                setCategories(categories);
            } else console.log("getMyCategories unsuccessful: ", mcategories);
            getNextDeliveryDate(customer);
            GetSetting("ShowNextDeliveryDate", "0").then((value) => {
                setShowNextDeliveryDate(value === "0");
                console.log("ShowNextDeliveryDate: ", value);
            });
        };
        getMyCategories();
        getScheduleAddress(GetUserName()).then((address) => {
            setAddresses(address);
            if (address && address.length > 0 && (!currentAddress || !currentAddress?.cid))
                setCurrentAddress && setCurrentAddress(address[0]);
        });
        getSpecialImages().then((images) => {
            if (images?.Images) setSpecialImages(images.Images);
            console.log("specialImages: ", images?.Images);
        });
        const key = GetUserName() + "_Cart";
        console.log("Restoring Cart.", key);
        const pastCart = localStorage.getItem(key);
        if (pastCart) {
            console.log("Restoring Past Cart.  ", pastCart);
            setCartItems(JSON.parse(pastCart));
        }
    }, []);

    async function getNextDeliveryDate(customer: Customer) {
        try {
            const token = await API_Authenticate();
            const ddate = await API_GetNextDeliveryDate(token, customer.ccustno, customer.cschedid);
            if (ddate?.DeliveryDate) {
                console.log("getNextDeliveryDate: ", ddate.DeliveryDate);
                var deliveryDate = new Date(ddate.DeliveryDate);
                if (deliveryDate) setNextDeliveryDate(getFormattedDate(deliveryDate));
            }
        } catch (err) {
            console.log("Error getting next delivery date: ", err);
        }
    }

    const indexOfLastItem = currentPage * ItemsPerPage;
    const indexOfFirstItem = indexOfLastItem - ItemsPerPage;
    /*const currentItems = getFilteredProducts().slice(
    indexOfFirstItem,
    indexOfLastItem,
  );*/

    const currentItems = apiProducts;

    useEffect(() => {
        console.log("apiProducts: ", JSON.stringify(apiProducts));
    }, [apiProducts]);

    useEffect(() => {
        if (addresses.length === 1) {
            setSelectedAddress(addresses[0].cid);
        }
    }, [addresses]);

    useEffect(() => {
        if (selectedAddress !== "") {
            setAddressLabel("Schedule/Address");
            var customer = getCustomerFromAddress(selectedAddress);
            if (customer) setCustomer(customer);
            var address = getAddressFromCID(selectedAddress);
            if (address) setCurrentAddress && setCurrentAddress(address);

            if (customer) getNextDeliveryDate(customer);
        } else setAddressLabel("Please select Schedule/Shipping address...");
    }, [selectedAddress]);

    useEffect(() => {
        setTotalItems(getTotalItems(cartItems));

        if (cartItems.length > 0) {
            const key = GetUserName() + "_Cart";
            console.log("Saving Cart.", key);
            const jsonCart = JSON.stringify(cartItems);
            console.log("Cart Items", jsonCart);
            localStorage.setItem(key, jsonCart);
        }
    }, [cartItems]);

    useEffect(() => {
        if (cartOpen) {
            setCheckout(false);
            //setShowItem(false);
        }
    }, [cartOpen]);

    function handleSortChange(value: string): void {
        setSort(value);
    }

    const sliderSettings = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 9000,
    };

    //console.log("li-" + indexOfLastItem);
    //console.log("fi-" + indexOfFirstItem);
    //console.log("cp-" + currentPage);
    /*
    if (isLoading) return <LinearProgress />;
    if (error) return <div>An error has occurred: {error.message}</div>;
*/
    return (
        <>
            {isLoading && !startCheckout ?
                <LoadingSpinner message='Loading items...' />
            :   <>
                    {/*<Drawer anchor="right" open={cartOpen} onClose={() => setCartOpen(false)}>
        <div style={{ marginTop: 25 }}>
          <Cart
            cartItems={getUpdatedPriceCartItems()}
            addToCart={handleAddToCart}
            removeFromCart={handleRemoveFromCart}
            checkout={handleCheckout}
          />
        </div>
      </Drawer>*/}
                    <>
                        <Dialog
                            fullWidth
                            maxWidth='md'
                            open={showItem}
                        >
                            <Box className='float-right'>
                                <IconButton
                                    className='float-right'
                                    onClick={() => setShowItem(false)}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Box>
                                <Item
                                    item={itemToDisplay}
                                    customer={customer}
                                    handleAddToCart={handleAddToCart}
                                    handleItemDisplayClose={handleItemDisplayClose}
                                    handleItemDisplay={handleItemDisplay}
                                />
                            </Box>
                        </Dialog>
                        <Box
                            sx={{
                                maxWidth:
                                    isLessThanFullWidth ?
                                        isMobile ? "100vw"
                                        :   "Calc(100vw - 300px)"
                                    :   "1120px",
                                padding: "0px",
                            }}
                        >
                            <Stack
                                alignItems='left'
                                spacing={2}
                            >
                                <Grid
                                    className='store-top'
                                    container
                                    spacing={2}
                                >
                                    {specialImages && specialImages.length > 0 && (
                                        <Grid
                                            className='image-carousel'
                                            item
                                            padding='30px'
                                            xs={12}
                                            md={12}
                                        >
                                            <Slider {...sliderSettings}>
                                                {specialImages.map((item: any, index: number) => (
                                                    <div
                                                        key={index}
                                                        onClick={() =>
                                                            handleSpecialItemDisplay(item)
                                                        }
                                                    >
                                                        <img
                                                            src={item.cfullfilename}
                                                            alt={item.calt}
                                                        />
                                                    </div>
                                                    /*<Box
                                                        component='img'
                                                        src={item.cfullfilename}
                                                        alt={item.calt}
                                                        key={index}
                                                        onClick={() =>
                                                            handleSpecialItemDisplay(item)
                                                        }
                                                    />*/
                                                ))}

                                                {/*{content.map((item, index) => (
                <div
                  key={index}
                  style={{ background: `url('${item.image}') no-repeat center center` }}
                >
                  <div className="center">
                    <h1>{item.title}</h1>
                    <p>{item.description}</p>
                    <button>{item.button}</button>
                  </div>
                </div>
              ))}*/}
                                            </Slider>
                                        </Grid>
                                    )}
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                    >
                                        <Flex
                                            direction='row'
                                            justifyContent='space-between'
                                        >
                                            {showNextDeliveryDate ?
                                                <Typography className='next_delivery_on'>
                                                    Next Delivery On: {nextDeliveryDate}
                                                </Typography>
                                            :   <div> </div>}

                                            <Stack direction='column'>
                                                <FormControl
                                                    variant='standard'
                                                    size='small'
                                                >
                                                    <Select
                                                        id='items-per-page'
                                                        value={ItemsPerPage.toString()}
                                                        onChange={handleItemsPerPageChange}
                                                        label='Items Per Page'
                                                    >
                                                        <MenuItem value={6}>
                                                            Show 9 Per Page
                                                        </MenuItem>
                                                        <MenuItem value={9}>
                                                            Show 9 Per Page{" "}
                                                        </MenuItem>
                                                        <MenuItem value={12}>
                                                            Show 12 Per Page{" "}
                                                        </MenuItem>
                                                        <MenuItem value={24}>
                                                            Show 24 Per Page{" "}
                                                        </MenuItem>
                                                        <MenuItem value={36}>
                                                            Show 36 Per Page{" "}
                                                        </MenuItem>
                                                        <MenuItem value={48}>
                                                            Show 48 Per Page{" "}
                                                        </MenuItem>
                                                        <MenuItem value={60}>
                                                            Show 60 Per Page{" "}
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Stack>
                                        </Flex>
                                    </Grid>
                                </Grid>

                                <Stack
                                    alignItems={isMobile ? "center" : "left"}
                                    direction={isMobile ? "column" : "row"}
                                    sx={{ flexWrap: "wrap", gap: 1 }}
                                >
                                    {/*<Box
                                            className='parent'
                                            m={2}
                                            display='flex'
                                            justifyContent='left'
                                            sx={{ flexWrap: "wrap", alignItems: "center" }}
                                                >*/}
                                    {/*<Grid  item xs={12} md={4}>*/}
                                    <Box className='search_grid_items'>
                                        <Label className='search_textfields_label'>Search</Label>
                                        <TextField
                                            label='Search'
                                            labelHidden
                                            inputStyles={{
                                                backgroundColor: "white",
                                            }}
                                            className='search_textfields'
                                            onChange={(e) => setSearchInput(e.target.value)}
                                            value={searchInput}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    startSearch();
                                                }
                                            }}
                                            innerEndComponent={
                                                <FieldGroupIconButton
                                                    ariaLabel='Search'
                                                    variation='link'
                                                    onClick={() =>
                                                        searchInput === "" ? cancelSearch() : (
                                                            startSearch()
                                                        )
                                                    }
                                                >
                                                    <MdSearch />
                                                </FieldGroupIconButton>
                                            }
                                        />
                                    </Box>
                                    {/*</Grid>*/}
                                    {/*<Grid  item xs={6} md={4}>*/}
                                    {/*<SelectField
                          className="search_textfields"
                          id="sel-category"
                          onChange={handleStoreCategoryChange}
                          value={searchCategory.length > 0 ? searchCategory[0] : "ALL"}
                          label="Search Category"
                          labelHidden
                        >
                          <option className="search_textfields" key="ALL" value="ALL">
                            All
                          </option>
                          {categories &&
                            categories?.map((cat) => (
                              <option className="search_textfields" key={cat.ccatno} value={cat.ccatno}>
                                {cat.cdescript}
                              </option>
                            ))}
                        </SelectField>
                            */}
                                    <Box className='search_grid_items'>
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            <Grid
                                                item
                                                xs={8}
                                                lg={12}
                                            >
                                                <Label className='search_textfields_label'>
                                                    Sort By
                                                </Label>
                                                <SelectField
                                                    className='search_textfields'
                                                    labelHidden
                                                    id='sel-sort'
                                                    onChange={(event) =>
                                                        handleSortChange(event.target.value)
                                                    }
                                                    value={sort}
                                                    label='Sort By'
                                                >
                                                    <option
                                                        className='search_textfields'
                                                        key='A-Z'
                                                        value='A-Z'
                                                    >
                                                        Alphabetically, A-Z
                                                    </option>
                                                    <option
                                                        className='search_textfields'
                                                        key='Z-A'
                                                        value='Z-A'
                                                    >
                                                        Alphabetically, Z-A
                                                    </option>
                                                    <option
                                                        className='search_textfields'
                                                        key='PLH'
                                                        value='PLH'
                                                    >
                                                        Price, Low-High
                                                    </option>
                                                    <option
                                                        className='search_textfields'
                                                        key='PHL'
                                                        value='PHL'
                                                    >
                                                        Price, High-Low
                                                    </option>
                                                </SelectField>
                                            </Grid>
                                            <Hidden lgUp>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    lg={12}
                                                >
                                                    <div className='filters_button'>
                                                        <Button
                                                            style={{
                                                                backgroundColor: "#58B55D",
                                                            }}
                                                            variant='contained'
                                                            onClick={() => setSideBarOpen(true)}
                                                        >
                                                            Filters
                                                        </Button>
                                                    </div>
                                                </Grid>
                                            </Hidden>
                                        </Grid>
                                    </Box>
                                    <Box className='search_grid_items'>
                                        {/*<Grid  item xs={12} md={4}>*/}
                                        <Label className='search_textfields_label'>
                                            Schedule/Shipping Address
                                        </Label>
                                        <SelectField
                                            className='search_textfields'
                                            labelHidden
                                            id='sel-address'
                                            onChange={handleScheduleAddressChange}
                                            value={currentAddress?.cid}
                                            label={addressLabel}
                                            placeholder='Please select an Address...'
                                        >
                                            {addresses &&
                                                addresses?.map((addr) => (
                                                    <option
                                                        className='search_textfields'
                                                        key={addr.cid}
                                                        value={addr.cid}
                                                    >
                                                        {addr.caddr}
                                                        {/*<div dangerouslySetInnerHTML={{ __html: addr.caddr }} />*/}
                                                    </option>
                                                ))}
                                        </SelectField>
                                    </Box>
                                    {/*</Grid>*/}
                                    {/*</Box>*/}
                                </Stack>
                                {/*</Stack>*/}
                            </Stack>
                            <br />

                            {/* Drawer was here
            
            <div className="ShoppingCartButtonDiv">
              <IconButton
                className="ShoppingCartButton"
                onClick={() => setCartOpen(true)}
              >
                <Badge
                  badgeContent={getTotalItems(cartItems)}
                  color="secondary"
                >
                  <AddShoppingCartIcon />
                </Badge>
              </IconButton>
                    </div>*/}
                            {currentItems?.length === 0 ?
                                <>
                                    <Typography
                                        align='center'
                                        variant='h6'
                                    >
                                        No items found
                                    </Typography>
                                    <Box sx={{ minHeight: "Calc(100vh - 280px)" }} />
                                </>
                            :   <>
                                    {/*<Grid container spacing={1}>*/}
                                    <Stack
                                        alignItems={isMobile ? "center" : "left"}
                                        direction={isMobile ? "column" : "row"}
                                        sx={{ flexWrap: "wrap", gap: 1 }}
                                    >
                                        {/*<Box
                                                className='parent'
                                                display='flex'
                                                justifyContent='left'
                                                sx={{ flexWrap: "wrap", alignItems: "left" }}
                                >*/}
                                        {currentItems?.map((item) => (
                                            <div
                                                key={item.citemno}
                                                className='product_grid_items'
                                            >
                                                <ProductCard2
                                                    key={item.citemno}
                                                    item={item}
                                                    handleAddToCart={handleAddToCart}
                                                    handleItemDisplay={handleItemDisplay}
                                                />
                                            </div>
                                        ))}
                                        {/*</Box>*/}
                                    </Stack>
                                    {/*</Grid>*/}
                                    <br />
                                    <br />
                                    <Stack alignItems='center'>
                                        <div className='parent'>
                                            <div
                                                id='d1'
                                                className='inline-block-child'
                                            >
                                                <Pagination
                                                    count={numberOfPages}
                                                    page={currentPage}
                                                    onChange={(e, value) =>
                                                        setCurrentPage(Number(value))
                                                    }
                                                    defaultPage={1}
                                                    sx={{
                                                        "float": "right",
                                                        "mt": 2,
                                                        "& .MuiPaginationItem-root": {
                                                            "borderRadius": "4px",
                                                            "backgroundColor":
                                                                theme.palette.common.white,
                                                            "color": theme.palette.text.primary,
                                                            "border": `1px solid ${theme.palette.text.primary}`,
                                                            "&:hover": {
                                                                color: theme.palette.primary.main,
                                                                border: `2px solid ${theme.palette.primary.main}`,
                                                                backgroundColor:
                                                                    theme.palette.common.white,
                                                                fontWeight: 600,
                                                            },
                                                            "&.Mui-selected": {
                                                                backgroundColor:
                                                                    theme.palette.primary.main,
                                                                color: theme.palette.common.white,
                                                            },
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Stack>
                                </>
                            }
                            <Box sx={{ height: isMobile ? "150px" : "150px" }} />
                        </Box>
                    </>
                </>
            }
        </>
    );
};

export default StoreItems;
