import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { NumericFormat } from "react-number-format";
import { InvoiceCardProps } from "../interfaces/InvoiceInterfaces";
import { Box, Grid, Stack } from "@mui/material";
import { useIsMobile } from "helpers/CustomHooks";
import dayjs from "dayjs";

const InvoiceCard: React.FC<InvoiceCardProps> = (props) => {
    const isMobile = useIsMobile();

    function formatDateString(date: string) {
        try {
            return dayjs(date).format("MM/DD/YYYY");
        } catch (err) {
            console.log("Error formatting invoice date.", err);
        }

        return "";
    }

    return (
        <Box sx={{ width: "100%" }}>
            {/*<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Invoice(s)
        </Typography>*/}

            <Typography variant='body2'>
                <Stack spacing={0}>
                    <Box className='invoice_card_label'>
                        Invoice # {"(Selected " + props.numofinvoices + ")"}
                    </Box>
                    <Box className='invoice_card_invnums'>{props.invoicenums}</Box>
                    <Box height={10} />
                    <Grid
                        container
                        spacing={0}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={6}
                        >
                            <Grid
                                container
                                spacing={0}
                            >
                                <Grid
                                    className='invoice_card_label'
                                    item
                                    xs={6}
                                >
                                    Invoice Date
                                </Grid>
                                <Grid
                                    className='invoice_card_label'
                                    item
                                    xs={6}
                                >
                                    PO #
                                </Grid>
                                <Grid
                                    className='invoice_card_values'
                                    item
                                    xs={6}
                                >
                                    {formatDateString(props.dinvoice)}
                                </Grid>
                                <Grid
                                    className='invoice_card_values'
                                    item
                                    xs={6}
                                >
                                    {props.cpono}
                                </Grid>
                            </Grid>
                        </Grid>
                        {isMobile && (
                            <Grid
                                item
                                xs={12}
                                sm={0}
                            >
                                <Box height={10} />
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={12}
                            sm={6}
                        >
                            <Grid
                                container
                                spacing={0}
                            >
                                <Grid
                                    className='invoice_card_label'
                                    item
                                    xs={6}
                                >
                                    Customer #
                                </Grid>
                                <Grid
                                    className='invoice_card_label'
                                    item
                                    xs={6}
                                >
                                    Site #
                                </Grid>
                                <Grid
                                    className='invoice_card_values'
                                    item
                                    xs={6}
                                >
                                    {props.ccustno}
                                </Grid>
                                <Grid
                                    className='invoice_card_values'
                                    item
                                    xs={6}
                                >
                                    {props.cscono}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box height={10} />
                    <Grid
                        container
                        spacing={0}
                    >
                        <Grid
                            className='invoice_card_label'
                            item
                            xs={12}
                        >
                            Company
                        </Grid>
                        <Grid
                            className='invoice_card_values'
                            item
                            xs={12}
                        >
                            {props.company}
                        </Grid>
                    </Grid>
                    <Box height={10} />
                    <Grid
                        container
                        spacing={0}
                    >
                        <Grid
                            className='invoice_card_label'
                            item
                            xs={12}
                        >
                            Site Address
                        </Grid>
                        <Grid
                            className='invoice_card_values'
                            item
                            xs={12}
                        >
                            {props.caddress}
                            <br />
                            {props.csphone}
                        </Grid>
                    </Grid>
                </Stack>

                {/* <table>
                        <tr>
                            <td className='text-table-pad'>
                                <b>Num of Invoices:</b>
                            </td>
                            <td className='text-table-pad'>{props.numofinvoices}</td>
                        </tr>
                        <tr>
                            <td className='text-table-pad'>
                                <b>Invoice #(s):</b>
                            </td>
                            <td className='text-table-pad'>{props.invoicenums}</td>
                        </tr>
                        <tr>
                            <td className='text-table-pad'>
                                <b>Company Name:</b>
                            </td>
                            <td className='text-table-pad'>{props.company}</td>
                        </tr>
                        <tr>
                            <td className='text-table-pad'>
                                <b>Total Due:</b>
                            </td>
                            <td className='text-table-pad'>
                                <NumericFormat
                                    value={props.total}
                                    displayType={"text"}
                                    thousandSeparator=','
                                    decimalScale={2}
                                    fixedDecimalScale
                                    prefix={"$"}
                                    renderText={(formattedvalue: any) => {
                                        return formattedvalue;
                                    }}
                                />
                            </td>
                        </tr>
                        {props.credits > 0 ?
                            <tr>
                                <td className='text-table-pad'>
                                    <b>Credits:</b>
                                </td>
                                <td className='text-table-pad'>
                                    <NumericFormat
                                        value={props.credits}
                                        displayType={"text"}
                                        thousandSeparator=','
                                        decimalScale={2}
                                        fixedDecimalScale
                                        prefix={"$"}
                                        renderText={(formattedvalue: any) => {
                                            return formattedvalue;
                                        }}
                                    />
                                </td>
                            </tr>
                        :   null}
                                    </table>*/}
            </Typography>
        </Box>
    );
};

export default InvoiceCard;
