import { Box, Button, Divider } from "@mui/material";
import React from "react";
import NavBreadcrumbs from "../helpers/NavBreadcrumbs";
import PrintInvoices from "./PrintInvoices";
import PrintTickets from "./PrintTickets";

const ReportsPage = () => {
    const [view, setView] = React.useState("printTickets");

    return (
        <Box sx={{ width: "100%", typography: "body1" }}>
            <NavBreadcrumbs currentView={view} />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                    onClick={() => setView("printTickets")}
                    sx={{
                        textTransform: "none",
                        fontFamily: "Montserrat",
                        color: view === "printTickets" ? "#1E2832" : "#DADCE0",
                        fontWeight: view === "printTickets" ? 600 : 500,
                        fontSize: 16,
                        marginRight: 1,
                        opacity: view === "printTickets" ? 1 : 1,
                    }}
                >
                    Tickets
                </Button>
                <Button
                    onClick={() => setView("printInvoices")}
                    sx={{
                        textTransform: "none",
                        fontFamily: "Montserrat",
                        color: view === "printInvoices" ? "#1E2832" : "#DADCE0",
                        fontWeight: view === "printInvoices" ? 600 : 500,
                        fontSize: 16,
                        marginLeft: 1,
                        opacity: view === "printInvoices" ? 1 : 1,
                    }}
                >
                    Invoices
                </Button>
            </Box>
            <Divider
                variant='middle'
                sx={{
                    mb: 2,
                    borderWidth: 1,
                    borderColor: "divider",
                }}
            />
            {view === "printTickets" && <PrintTickets />}
            {view === "printInvoices" && <PrintInvoices />}
        </Box>
    );
};

export default ReportsPage;
