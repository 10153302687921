import { Button, Flex, TextField } from "@aws-amplify/ui-react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { experimentalStyled as styled } from "@mui/material/styles";
import * as React from "react";
import { GetTenant } from "../services/GetInvoiceData";
import { SelectAllUsers, SelectUsersWithTenants, UserWithTenants } from "../services/GraphQlHelper";
import { getOverrideProps } from "../ui-components/utils";
import UpdateCustomers from "./UpdateCustomers";
import UpdateUsersTenants from "./UpdateUsersTenants";
import prismTheme from "theme/theme";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: prismTheme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...prismTheme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: prismTheme.palette.text.secondary,
}));

interface UpdateUsersProps {
    onSuccess: () => void;
}

export default function UpdateUsers(props: UpdateUsersProps) {
    const [selectedPage, setSelectedPage] = React.useState("default");
    const [users, setUsers] = React.useState([] as UserWithTenants[]);
    const [userFilterField, setUserFilterField] = React.useState("");
    const [userFilter, setUserFilter] = React.useState("");
    const [searchMode, setSearchMode] = React.useState(false);
    const [currentTenant, setCurrentTenant] = React.useState("");

    function UsersTable() {
        console.log("Calling UsersTable");
        return (
            <>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 650 }}
                        aria-label='simple table'
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell align='left'>
                                    <h5>User</h5>
                                </TableCell>
                                <TableCell align='left'>
                                    <h5>Tenants</h5>
                                </TableCell>
                                <TableCell align='left'>
                                    <h5>Update</h5>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users
                                .filter(
                                    (row) =>
                                        userFilter === "" ||
                                        row.userid.includes(userFilter) ||
                                        row.tenants.includes(userFilter),
                                )
                                .sort((a, b) => (a > b ? 1 : -1))
                                .map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            "&:last-child td, &:last-child th": { border: 0 },
                                        }}
                                    >
                                        <TableCell
                                            component='th'
                                            scope='row'
                                        >
                                            {row.userid}
                                        </TableCell>
                                        <TableCell
                                            component='th'
                                            scope='row'
                                        >
                                            {row.tenants.join(", ")}
                                        </TableCell>
                                        <TableCell
                                            width={"370px"}
                                            align='right'
                                        >
                                            <Flex direction='row'>
                                                <Button
                                                    onClick={() => {
                                                        setSelectedPage(row.userid);
                                                    }}
                                                >
                                                    Tenants
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setSelectedPage(
                                                            "UpdateCustomers:" + row.userid,
                                                        );
                                                    }}
                                                >
                                                    Customers
                                                </Button>
                                            </Flex>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }

    React.useEffect(() => {
        const queryData = async () => {
            try {
                console.log("getting user/tenants:");
                var users = await SelectUsersWithTenants();
                console.log("users with tenants: ", users);
                if (!users) {
                    users = [] as UserWithTenants[];
                    console.log("users with tenants2: ", users);
                }
                const allusers = await SelectAllUsers();
                var usersall = allusers?.data?.listUsers?.items || [];
                console.log("usersall: ", usersall);
                if (usersall) {
                    usersall.forEach((element) => {
                        if (!users.find((u) => u.userid === element.username)) {
                            users.push({
                                userid: element.username,
                                tenants: [],
                            });
                        }
                    });
                }

                setUsers(users);
                console.log("users: ", users);

                var tenant = GetTenant();
                if (tenant) {
                    setCurrentTenant(tenant.subdomain);
                    console.log("Current tenant: ", tenant);
                } else {
                    console.log("No tenant found");
                }
            } catch (error) {
                console.log("Error when trying to get users", error);
            }
        };
        queryData();
    }, []);

    /*    React.useEffect(() => {
        //let unames = [] as string[];
        let unames: {userid: string, tenants: string[]}[] = [];

  
        SelectUsersWithTenants().then((users) => {
            if(users)
                setUsers(users);
        })

            var tenant = GetTenant();
            if(tenant) {
                setCurrentTenant(tenant.id);            
                console.log("Current tenant: ", tenant);    
            }
            else
            {
                console.log("No tenant found");
            }
        

    }, [])*/

    function showPage() {
        if (selectedPage.startsWith("UpdateCustomers:")) {
            const id = selectedPage.replace("UpdateCustomers:", "");
            return (
                <div>
                    <h3>{id}'s Linked Customers</h3>
                    <br />
                    <h5>
                        This tool will only allow you to add customers for the tenant you are
                        currently logged into. {"[" + currentTenant + "]"}
                    </h5>
                    <UpdateCustomers
                        userid={id}
                        onSuccess={() => {
                            setSelectedPage("default");
                        }}
                    />
                </div>
            );
        }

        switch (selectedPage) {
            case "default":
                return (
                    <>
                        <UsersTable />
                        <br />
                        <br />
                        <Button
                            children='Go Back'
                            type='reset'
                            onClick={(event) => {
                                event.preventDefault();
                                props.onSuccess();
                            }}
                            {...getOverrideProps(null, "ClearButton")}
                        ></Button>
                    </>
                );
            default:
                return (
                    <div>
                        <h3>{selectedPage}</h3>
                        <UpdateUsersTenants
                            idProp={selectedPage}
                            onSuccess={() => {
                                setSelectedPage("default");
                            }}
                        />
                    </div>
                );
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            {selectedPage === "default" && (
                <TextField
                    label='Filter'
                    value={userFilterField}
                    outerEndComponent={
                        <>
                            <Button onClick={() => setUserFilter(userFilterField)}>Search</Button>
                            <Button
                                onClick={() => {
                                    setUserFilterField("");
                                    setUserFilter("");
                                }}
                            >
                                Clear
                            </Button>
                        </>
                    }
                    onChange={(event) => setUserFilterField(event.currentTarget.value)}
                />
            )}
            {showPage()}
        </Box>
    );
}
