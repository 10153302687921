import { Box, Stack } from "@mui/material";
import * as React from "react";
import { API_Authenticate, API_GetSuggestedItems } from "../services/GetInvoiceData";
import ProductCard2 from "./Item/ProductCard";
import { Customer, WebInventoryItem } from "./StoreItems";

type Props = {
    citemno: string;
    customer: Customer;
    handleAddToCart: (clickedItem: WebInventoryItem) => void;
    handleItemDisplay: (clickedItem: WebInventoryItem) => void;
};

const SuggestedItems: React.FC<Props> = ({
    citemno,
    customer,
    handleAddToCart,
    handleItemDisplay,
}) => {
    const [sugItems, setSugItems] = React.useState([] as WebInventoryItem[]);

    React.useEffect(() => {
        const fetchData = async () => {
            console.log("Getting suggested items.");
            const token = await API_Authenticate();
            const sItems = await API_GetSuggestedItems(
                token,
                citemno,
                customer.ccustno,
                customer.cscono,
                customer.cschedid,
            );
            console.log("sItems: ", sItems);
            setSugItems(sItems.Items as WebInventoryItem[]);
        };

        fetchData();
    }, [citemno, customer]);

    return (
        <>
            {/*<Grid container spacing={1}>*/}

            {sugItems && sugItems.length > 0 ?
                <>
                    <h3> Suggested Items</h3>
                    <Stack alignItems='center'>
                        <Box
                            m={2}
                            display='flex'
                            justifyContent='center'
                            sx={{ flexWrap: "wrap", alignItems: "center" }}
                        >
                            {sugItems?.map((item) => (
                                <div
                                    key={item.citemno}
                                    style={{ padding: "10px" }}
                                >
                                    <ProductCard2
                                        key={item.citemno}
                                        item={item}
                                        handleAddToCart={handleAddToCart}
                                        handleItemDisplay={handleItemDisplay}
                                    />
                                </div>
                            ))}
                        </Box>
                    </Stack>
                </>
            :   null}
        </>
    );
};

export default SuggestedItems;
