import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import {
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
    type MRT_RowSelectionState,
} from "material-react-table";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    CCProps,
    CreditCard,
    PayMethodCustomers,
    ScheduleAddress,
} from "../interfaces/InvoiceInterfaces";
import {
    API_Authenticate,
    API_GetPaymentMethods,
    API_GetScheduleAddresses,
    GetUserName,
} from "../services/GetInvoiceData";
import CreditCardInformation from "./CreditCardInformation";
import { Card, SelectField } from "@aws-amplify/ui-react";
import { Grid, Box, Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import CreditCardTile from "./CreditCardTile";
import { GreenButton } from "./Buttons";
import { useIsMobile, useScreenWidth } from "helpers/CustomHooks";
import { isMap } from "util/types";

const ModifyCreditCards: React.FC<CCProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = useState<CreditCard[]>([]);
    const [authToken, setAuthToken] = useState("");
    const [loading, setLoading] = useState(true);
    const [reloadData, setReloadData] = useState(true);
    const [currentCcustno, setCurrentCcustno] = useState("");
    const [allowCustomerSelection, setAllowCustomerSelection] = useState(false);
    const [showAllFields, setShowAllFields] = useState(true);
    const [customers, setCustomers] = useState([] as PayMethodCustomers[]);
    const [customer, setCustomer] = useState("");
    const [selectedPayProfileID, setSelectedPayProfileID] = useState("0");
    const [selectedCustomerProfileID, setSelectedCustomerProfileID] = useState("0");

    const location = useLocation();

    const isMobile = useIsMobile();
    const screenWidth = useScreenWidth();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (nprofid: string, saved: boolean) => {
        setOpen(false);
        if (saved && nprofid !== "0") {
            console.log("modifycreditcards onClose: ", nprofid);
            setReloadData(true);
            props.onCCChange && props.onCCChange(nprofid);
            setSelectedCustomerProfileID(nprofid);
        }
    };

    const handleNonSavedClose = (nprofid: string) => {
        handleClose("0", false);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const token = await API_Authenticate();
                setAuthToken(token);
                const paymentMethodData = await API_GetPaymentMethods(customer, token);
                console.log("ccustno: ", customer);
                console.log("paymentmethods: ", paymentMethodData);
                setData(paymentMethodData?.PaymentProfiles);
            } catch (error) {
                console.error("Failed to load data: ", error);
            } finally {
                setLoading(false);
            }
        };

        console.log("updating customers: customer: ", customer);
        console.log("updating customers: currentCcustno: ", currentCcustno);
        console.log("updating customers: location?.state?.ccustno: ", location?.state?.ccustno);
        if (customer === "NEEDTOSELECTCUSTOMER" && location?.state?.ccustno) {
            setCustomer(location?.state?.ccustno);
            return;
        }

        if (customer === "NEEDTOSELECTCUSTOMER") {
            setAllowCustomerSelection(true);
            setShowAllFields(false);
        } else if (customer !== currentCcustno || reloadData) {
            console.log("reloadingData: ");
            fetchData();
            setShowAllFields(true);
            setCurrentCcustno(customer);
            setReloadData(false);
        }
    }, [customer, reloadData]);

    function removeDuplicateCustomers(customers: PayMethodCustomers[]) {
        return customers.filter(
            (customer, index) =>
                customers.findIndex((c) => c.ccustno === customer.ccustno) === index,
        );
    }

    useEffect(() => {
        const fetchAddresses = async () => {
            const token = await API_Authenticate();
            const addrs = await API_GetScheduleAddresses(token, GetUserName(), "D");
            if (!addrs.IsSuccessful) {
                console.log(
                    "Error getting schedule addresses. (" +
                        addrs.ErrorCode +
                        ": " +
                        addrs.Error +
                        ")",
                );
                return [];
            }
            var custs = addrs.Addresses.map((addr: ScheduleAddress) => {
                return { ccustno: addr.ccustno, ccompany: addr.ccompany };
            });

            if (!custs) return;

            custs = removeDuplicateCustomers(custs);

            setCustomers(custs);
            setCurrentCcustno("");
            if (custs.length === 1) setCustomer(custs[0].ccustno);
            else setCustomer(props.ccustno);
        };

        fetchAddresses();
    }, []);

    function handleCustomerChange(event: React.ChangeEvent<HTMLSelectElement>): void {
        console.log("handleCustomerChange: ", event.target.value);
        setCustomer(event.target.value);
    }

    //do something when the row selection changes...
    //useEffect(() => {
    //  console.info({ rowSelection }); //read your managed row selection state
    // console.info(table.getState().rowSelection); //alternate way to get the row selection state
    //}, []);

    return (
        <>
            <>
                <Box sx={{ width: "100vw" }} />
                {/*This box is here for sizing purposes*/}
                <Dialog open={open}>
                    <DialogTitle>
                        <Typography
                            variant='h6'
                            sx={{ textAlign: "center" }}
                        >
                            Add New Credit Card
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <CreditCardInformation
                            ccustno={customer}
                            data={null}
                            isNew={true}
                            onClose={handleClose}
                        />
                    </DialogContent>
                </Dialog>
                {/*} <Modal
                    open={open}
                    sx={{ overflow: "scroll", display: "block" }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: screenWidth > 450 ? 450 : "99vw",
                            bgcolor: "white",
                            border: "2px solid #000",
                            boxShadow: 1,
                            p: 4,
                        }}
                    >
                        <Typography
                            variant='h6'
                            sx={{ textAlign: "center" }}
                        >
                            Add New Credit Card
                        </Typography>
                        <CreditCardInformation
                            ccustno={customer}
                            data={null}
                            isNew={true}
                            onClose={handleClose}
                        />
                    </Box>
                    </Modal>*/}
                <Stack
                    direction='column'
                    spacing={2}
                    alignItems='center'
                    sx={{ marginBottom: 2, marginTop: isMobile ? 5 : 0 }}
                >
                    <Typography variant='h5'>Modify Payment Methods</Typography>
                </Stack>
                <Grid
                    container
                    width={"100%"}
                >
                    <Grid
                        item
                        xs={12}
                        sm={6}
                    >
                        <SelectField
                            width={isMobile ? "100%" : "300px"}
                            marginTop={isMobile ? 2 : 0}
                            labelHidden
                            id='sel-customer'
                            onChange={handleCustomerChange}
                            value={customer === "NEEDTOSELECTCUSTOMER" ? "" : customer}
                            label='Customer'
                            placeholder='Please select a Customer'
                        >
                            {customers &&
                                customers?.map((cust, index) => (
                                    <option
                                        className='search_textfields'
                                        key={index}
                                        value={cust.ccustno}
                                    >
                                        {cust && cust.ccustno + " - " + cust.ccompany}
                                    </option>
                                ))}
                        </SelectField>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                    >
                        {showAllFields && (
                            <GreenButton
                                className={isMobile ? "" : "float-right"}
                                sx={{ width: isMobile ? "100%" : "200px", mt: isMobile ? 2 : 0 }}
                                onClick={() => setOpen(true)}
                            >
                                Add Payment Method
                            </GreenButton>
                        )}
                    </Grid>
                </Grid>
                <br />
                {showAllFields ?
                    <Box>
                        <Stack
                            direction='column'
                            spacing={2}
                            justifyContent={"center"}
                            alignContent={"center"}
                            alignItems={"center"}
                            width={"100%"}
                        >
                            {data &&
                                data
                                    .sort((a, b) => b.ldefault - a.ldefault)
                                    .map((pay, index) => (
                                        <CreditCardTile
                                            key={index}
                                            ccustno={customer}
                                            paymentInfo={pay}
                                            setReloadData={setReloadData}
                                        />
                                    ))}
                        </Stack>
                    </Box>
                :   <Box>
                        <br /> <br /> <br />
                        <h3>Please Select a customer...</h3>
                    </Box>
                }
            </>
        </>
    );
};

export default ModifyCreditCards;

/*<Dialog open={open} onClose={handleNonSavedClose} 
       PaperProps={{
        sx: {
          width: 900,
          maxHeight: 640
        }
      }}>
         
            <DialogContent>
            <CreditCardInformation ccustno={customer} data={(selectedPayProfileID==="0") ? null : table.getSelectedRowModel().rows[0]} isNew={(selectedPayProfileID==="0")} onClose={handleClose} />
            </DialogContent>
         
    </Dialog>*/
