import "@fontsource/montserrat";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Checkbox, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";
import type {} from "@mui/x-tree-view/themeAugmentation";
import React, { useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import "../styles.css";
import { Category } from "./StoreItems";
import theme from "theme/theme";

/*const theme = createTheme({
  components: {
    MuiTreeItem: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState. && ownerState.mui === {1===1} && {
              backgroundColor: '#202020',
              color: '#fff',
            }),
          }),
      },
    },
  },
});
*/

const StyledCheckbox = styled(Checkbox)({
    "&.MuiCheckbox-root": {
        color: "#58B55D;",
        padding: "1px",
    },
    "&.MuiCheckbox-colorSecondary": {
        "&.Mui-checked": {
            color: "#58B55D;",
            padding: "1px",
        },
    },
});

const StyledRadio = styled(Radio)({
    "&.MuiRadio-root": {
        color: "#58B55D;",
        padding: "1px",
    },
    "&.MuiRadio-colorSecondary": {
        "&.Mui-checked": {
            color: "#58B55D;",
            padding: "1px",
        },
    },
});

const StyledTreeView = styled(TreeView)({
    // selected and (selected + hover) states
    "&& .Mui-selected, && .Mui-selected:hover": {
        "backgroundColor": "white",
        "padding": "1px",
        "&, & .MuiTreeItem-root": {
            color: "black",
            padding: "1px",
        },
    },
});

const StyledTreeItem = styled(TreeItem)({
    // selected and (selected + hover) states
    // hover states
    "& .MuiTreeItem-label:hover": {
        "backgroundColor": "#87CEEB",
        "padding": "1px",
        "&, & .MuiTreeItem-label": {
            color: "black",
            padding: "1px",
        },
    },
});

export const Wrapper = styled.div`
    max-width: 300px;
    font-size: 8px !important;
`;

type Props = {
    categories?: Category[];
    onFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onCategoryChange: (event: React.SyntheticEvent, nodeId: string[]) => void;
    searchCategory?: string[];
    setSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
    category?: string[];
    filter?: string;
    setSelectedCategory?: React.Dispatch<React.SetStateAction<string[]>>;
    setSelectedFilter?: React.Dispatch<React.SetStateAction<string>>;
    isDialog: boolean;
};

const StoreSideBar: React.FC<Props> = ({
    onFilterChange,
    onCategoryChange,
    categories,
    searchCategory,
    setSideBarOpen,
    category,
    filter,
    setSelectedCategory,
    setSelectedFilter,
    isDialog,
}) => {
    //const [category, setCategory] = React.useState("ALL");
    //const [filter, setFilter] = React.useState("0");
    const [expandedNodes, setExpandedNodes] = React.useState<string[]>(["ALL"]);
    const [treeNodes, setTreeNodes] = React.useState<any[]>([]);
    const [localSelectedCategory, setLocalSelectedCategory] = React.useState<string[]>([]);
    const [localSelectedFilter, setLocalSelectedFilter] = React.useState<string>("0");

    function onlyUnique(value: string, index: number, array: string[]) {
        return array.indexOf(value) === index;
    }

    function getOnChange(checked: boolean, ccatno: string) {
        console.log("getOnChange: checked=", checked, " ccatno=", ccatno);
        const children = getTreeNodesByCategory(ccatno);

        if (ccatno === "ALL" && categories) {
            setSelCategories(checked ? categories?.map((c) => c.ccatno) : ["NONE"]);
            return;
        }

        let array =
            checked ?
                [...localSelectedCategory, ccatno, ...children]
            :   localSelectedCategory.filter(
                    (value) =>
                        categories &&
                        value &&
                        value !== ccatno &&
                        !children.some((x) => x === value),
                );

        if (array.length > 1 && array.indexOf("NONE") !== -1) {
            array = array.filter((x) => x !== "NONE");
        }

        array = array.filter(onlyUnique);

        console.log("getOnChange: array=", array);
        console.log("getOnChange: categories=", categories);
        console.log("getOnChange: array.length=", array.length);
        console.log("getOnChange: categories.length=", categories?.length);
        if (categories && array.length === categories.length - 1 && array.indexOf("ALL") === -1) {
            array.push("ALL");
        }

        if (categories && array.length < categories.length && array.indexOf("ALL") !== -1) {
            array = array.filter((x) => x !== "ALL");
        }

        setSelCategories(array);
    }

    function setSelCategories(cats: string[]) {
        setLocalSelectedCategory(cats);

        if (!isDialog) {
            setSelectedCategory && setSelectedCategory(cats);
        }
    }

    function setSelFilter(filter: string) {
        setLocalSelectedFilter(filter);

        if (!isDialog) {
            setSelectedFilter && setSelectedFilter(filter);
        }
    }

    /*function getSelCategories(): string[] {
    if(isDialog) {
     return localSelectedCategory || []; 
    }
    else{
        return category || [];
    }
  }

  function getSelFilter(): string | undefined { 
    if(isDialog) {
     return localSelectedFilter; 
    }
    else{
        return filter;
    }
  }*/

    function getTreeNodesByCategory(ccatno: string): string[] {
        var allNodes: string[] = [];
        allNodes.push(ccatno);
        const nodes = categories && categories.filter((c) => c.cparentno === ccatno);
        if (nodes && nodes.length > 0) {
            nodes.forEach((node) => {
                allNodes = [...allNodes, ...getTreeNodesByCategory(node.ccatno)];
            });
        }

        return allNodes;
    }

    const getTreeNodesStart = (cparentno: string) => {
        console.log("getTreeNodesStart: cparentno=", cparentno);
        const nodes = getTreeNodes(cparentno);

        return nodes;
    };

    const getTreeNodes = (cparentno: string) => {
        console.log("getTreeNodes: cparentno=", cparentno);
        console.log("getTreeNodes: categories=", categories);
        const nodes = categories && categories.filter((c) => c.cparentno === "ALL");

        const cb2 = (e: Category) => {
            console.log("cb2: e=", e);
            console.log("cb2: categories=", categories);
            const children = categories && categories.filter((c) => c.cparentno === e.ccatno);
            if (children && children.length > 0) {
                return children.map(cb);
            }
            return [] as JSX.Element[];
        };
        const cbold = (e: Category) => {
            return (
                <TreeItem
                    className='filters_treeitem'
                    nodeId={e.ccatno}
                    itemID={e.ccatno}
                    label={e.cdescript}
                    key={e.ccatno}
                >
                    {cb2(e)}
                </TreeItem>
            );
        };

        const cb = (e: Category) => {
            return (
                <TreeItem
                    className='filters_treeitem'
                    nodeId={e.ccatno}
                    itemID={e.ccatno}
                    key={e.ccatno}
                    label={
                        <FormControlLabel
                            className='filters_text'
                            control={
                                <StyledCheckbox
                                    checked={localSelectedCategory.some(
                                        (item) => item === e.ccatno,
                                    )}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        getOnChange(
                                            !localSelectedCategory.some(
                                                (item) => item === e.ccatno,
                                            ),
                                            e.ccatno,
                                        );
                                    }}
                                />
                            }
                            label={<div className='filters_text'>{e.cdescript}</div>}
                            value={e.ccatno}
                            key={e.ccatno}
                        />
                    }
                >
                    {cb2(e)}
                </TreeItem>
            );
        };

        //console.log("getTreeNodes: expandedNodes=", allNodes);
        //setExpanded(allNodes);
        if (nodes && nodes.length > 0) {
            const allNodes = nodes.map((node) => cb(node));

            return allNodes;
        }

        return [] as JSX.Element[];
    };

    /*const handleCategoryChange = (
    event: React.SyntheticEvent,
    nodeId: string,
  ) => {
    console.log("handleCategoryChange: nodeId=", nodeId);
    setCategory(nodeId);
    onCategoryChange(event, nodeId);
    setSideBarOpen(false);
  };*/

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setSelFilter((event.target as HTMLInputElement).value);
        /*onFilterChange(event);
    if (window.innerWidth < 1200) 
      setSideBarOpen(false);*/
    };

    const handleNodeSelect = (
        event: React.SyntheticEvent<Element, Event>,
        nodeId: string | string[],
    ) => {
        event.preventDefault();
        /*let selCat = "";
    if(Array.isArray(nodeId))
      selCat = nodeId[0];
    else
      selCat = nodeId;

    setSelectedCategory && setSelectedCategory([selCat);
    if (window.innerWidth < 1200) 
      setSideBarOpen(false);
    */
    };

    const applyAllFilters = () => {
        setSelectedCategory && setSelectedCategory(localSelectedCategory);
        setSelectedFilter && setSelectedFilter(localSelectedFilter);
        setSideBarOpen(false);
    };

    useEffect(() => {
        console.log("NavBar: useEffect searchCategory=", searchCategory);
        //if(searchCategory)
        //  setSelectedCategory && setSelectedCategory(searchCategory);
    }, [searchCategory]);

    /*useEffect(() => {
    if(!category)
      return;

    console.log("NavBar: useEffect category=", category);
    onCategoryChange({} as React.SyntheticEvent, category);
  }, [category]);*/

    useEffect(() => {
        category && setLocalSelectedCategory(category);
        filter && setLocalSelectedFilter(filter);

        const expanded = [];
        expanded.push("ALL");
        if (!categories) return;

        categories.map((c) => {
            expanded.push(c.ccatno);
        });

        console.log("NavBar: useEffect expanded=", expanded);

        console.log("NavBar: useEffect Category=", category);
        //if (!category || category === "") {
        //setSelectedCategory && setSelectedCategory(["ALL"]);
        // }
        //setCategory("ALL");
    }, []);

    useEffect(() => {
        setExpandedNodes(categories?.map((c) => c.ccatno) || []);
    }, [categories]);

    function onClearAll(): void {
        //setSelected([]);
        setSelCategories([]);
        setSelFilter("0");
    }

    return (
        <Box
            sx={{
                minHeight: "1228px",
                flexGrow: 1,
                maxWidth: 300,
            }}
            fontSize={9}
            bgcolor={"white"}
            boxShadow={1}
            height='100%'
            marginTop='28px'
            borderRadius={theme.shape.borderRadius}
        >
            <Box sx={{ marginLeft: 5 }}>
                {isDialog && (
                    <div className='filters_close_button'>
                        <IconButton onClick={() => setSideBarOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                )}
                <br />
                <br />
                <Grid
                    container
                    spacing={theme.spacing(1)}
                >
                    <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                    >
                        <div className='filters_label'>Filters</div>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                    >
                        {/*div className="filters_clearall" onClick={() => onClearAll()}>Clear all</div>*/}
                        <button
                            className='filters_clearall'
                            onClick={() => onClearAll()}
                        >
                            Clear all
                        </button>
                    </Grid>
                </Grid>
                <br />
                <div className='filters_header'>Refine By</div>

                <FormControl className='filters_radio'>
                    {/*onChange={handlePaymentMethodChange}*/}
                    <RadioGroup
                        className='filters_radio'
                        sx={{ m: 1 }}
                        aria-labelledby='demo-radio-buttons-group-label'
                        value={localSelectedFilter}
                        defaultValue='0'
                        name='radio-buttons-group'
                        onChange={handleFilterChange}
                    >
                        <div className='filters_radio'>
                            <FormControlLabel
                                className='filters_text'
                                value='0'
                                control={<StyledRadio className='primary_radio' />}
                                label={<div className='filters_text'>Entire Catalog</div>}
                            />

                            <FormControlLabel
                                className='filters_text'
                                value='1'
                                control={<StyledRadio className='primary_radio' />}
                                label={<div className='filters_text'>Approved Order Guide</div>}
                            />

                            <FormControlLabel
                                className='filters_text'
                                value='2'
                                control={<StyledRadio className='primary_radio' />}
                                label={<div className='filters_text'>Recent Purchases</div>}
                            />

                            <FormControlLabel
                                className='filters_text'
                                value='3'
                                control={<StyledRadio className='primary_radio' />}
                                label={<div className='filters_text'>In Cart with Quantity</div>}
                            />
                        </div>
                    </RadioGroup>
                </FormControl>
                <br />
                <br />
                <br />
                <div className='filters_header'>Categories</div>
                <br />
                <div>
                    <StyledTreeView
                        aria-label='category navigator'
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        defaultExpanded={expandedNodes}
                        expanded={expandedNodes}
                        selected={category}
                        onNodeSelect={handleNodeSelect}
                        //onNodeSelect={handleNodeSelect}
                    >
                        <StyledTreeItem
                            className='filters_treeitem'
                            nodeId='ALL'
                            key='0'
                            label={
                                <FormControlLabel
                                    className='filters_text'
                                    control={
                                        <StyledCheckbox
                                            checked={localSelectedCategory.some(
                                                (item) => item === "ALL",
                                            )}
                                            onChange={(event) =>
                                                getOnChange(event.currentTarget.checked, "ALL")
                                            }
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    }
                                    label={<div className='filters_text'>All</div>}
                                    value='0'
                                    key={"0"}
                                />
                            }
                        >
                            {getTreeNodesStart("")}
                            {/*<TreeItem nodeId="Category 1" label="Category1" key="1" />
              <TreeItem nodeId="Category 2" label="Category2" key="2">
                <TreeItem nodeId="Category 3" label="Category3" key="3" />
              </TreeItem>*/}
                        </StyledTreeItem>
                    </StyledTreeView>
                    <br />
                    <br />
                    {isDialog ?
                        <Button
                            style={{
                                backgroundColor: "#58B55D",
                            }}
                            variant='contained'
                            className='filters_button'
                            onClick={() => applyAllFilters()}
                        >
                            Apply Filters
                        </Button>
                    :   null}
                    <br />
                    <br />
                    <br />
                </div>
            </Box>
        </Box>
    );
};

export default StoreSideBar;
