import { Radio, Stack, styled } from "@mui/material";
import React from "react";
import { ScheduleAddress } from "./StoreItems";

type Props = {
    deliveryInstructions: string;
    paycode: string;
    address: ScheduleAddress;
};

const StyledRadio = styled(Radio)({
    "&.MuiRadio-root": {
        color: "#58B55D;",
        padding: "1px",
    },
    "&.MuiRadio-colorSecondary": {
        "&.Mui-checked": {
            color: "#58B55D;",
            padding: "1px",
        },
    },
});

const CheckoutOrderInfo: React.FC<Props> = ({ deliveryInstructions, address, paycode }) => {
    return (
        <Stack
            spacing={0}
            direction='column'
            sx={{ m: "20px" }}
        >
            <div className='checkout_info_label_bold'>Shipment Address</div>
            <div className='checkout_info_label'>
                123 Main St
                <br />
                Some City, CA 12345
            </div>
            <div>
                <br />
            </div>
            <div className='checkout_info_label'>
                Terms: <b>{paycode}</b>
            </div>
            <div>
                <br />
            </div>
            <div className='checkout_info_label_bold'>Delivery Instructions</div>
            <div className='checkout_info_label'>{deliveryInstructions}</div>
        </Stack>
    );
};

export default CheckoutOrderInfo;
