import styled from "styled-components";

export const Wrapper = styled.div`
    max-width: 800px;
    display: flex;
    flex-direction: column;
    padding: 20px;

    img {
        max-height: 300px;
        object-fit: cover;
    }

    .navbutton {
        width: 200px;
    }
`;
