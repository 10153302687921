// Import all imports under the IdentityImports namespace.
import * as IdentityImports from "../identity/helpers/imports";

// Import all types under the IdentityTypes namespace.
import * as IdentityTypes from "../identity/helpers/types";

// Import all configurations under the IdentityConfigs namespace.
import { IdentityConfigs } from "../identity/helpers/configs";

// Import the custom Amplify UI identity theme and flow.
import AmplifyFlow from "../identity/pages/AmplifyFlow";
import { IdentityTheme } from "../identity/pages/AmplifyFlow";
import { formFields } from "../identity/pages/AmplifyFlow";

IdentityImports.Hub.listen("auth", ({ payload }) => {
    switch (payload.event) {
        case "signedIn":
            console.log("user have been signedIn successfully.");
            break;
        case "signedOut":
            console.log("user have been signedOut successfully.");
            break;
        case "tokenRefresh":
            console.log("auth tokens have been refreshed.");
            break;
        case "tokenRefresh_failure":
            console.log("failure while refreshing auth tokens.");
            break;
        case "signInWithRedirect":
            console.log("signInWithRedirect API has successfully been resolved.");
            break;
        case "signInWithRedirect_failure":
            console.log("failure while trying to resolve signInWithRedirect API.");
            break;
        case "customOAuthState":
            console.log("custom state returned from CognitoHosted UI");
            break;
    }
});

const getDomain = () => {
    const host = window.location.host.split(":")[0];
    if (host.indexOf("localhost") >= 0) return "localhost";
    const spltAddr = host.split(".");
    if (spltAddr && spltAddr.length > 2) {
        return spltAddr[1] + "." + spltAddr[2];
    }

    return host;
};

const getCookieStorage = () => {
    let cs = new IdentityImports.CookieStorage();

    cs.domain = getDomain();
    cs.path = "/";
    cs.expires = 365;
    cs.sameSite = "strict";
    cs.secure = window.location.protocol === "https:";

    console.log("Window Protocol: ", window.location.protocol);
    console.log("CookieStorage: ", cs);

    return cs;
};

function converterRead(value: string, name: string) {
    if (value[0] === '"') {
        value = value.slice(1, -1);
    }
    return value.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent);
}

function ClearAllCookies() {
    if (typeof document === "undefined") return;

    const cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
        const cookieName = cookie.split("=")[0];
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        console.log("Clearing Cookie: ", cookieName);
    }
}

IdentityImports.Amplify.configure(IdentityConfigs.amplifyConfig);

IdentityImports.cognitoUserPoolsTokenProvider.setKeyValueStorage(getCookieStorage());

type AppProps = {
    signOut?: IdentityTypes.UseAuthenticator["signOut"]; //() => void;
    user?: IdentityTypes.AuthUser;
};

const Authenticate: React.FC<AppProps> = ({ signOut, user }) => {
    const [state, setState] = IdentityImports.useState({
        logo: "https://prism-web-images.s3.amazonaws.com/logos/prism.jpg",
        sideImage: "https://prism-web-images.s3.amazonaws.com/login_images/prism.jpg",
        loading: false,
        tenant: {} as IdentityTypes.GetTenantsQuery,
        invalidAccountNum: false,
        userTenants: {} as IdentityTypes.TenantsUsersByUsersIdQuery,
        needSelectTenant: false,
        authComponents: {},
        selectedTenantName: "",
        hideSignup: true,
        username: "",
        isAuthenticated: false,
        userNameExists: false,
        currentTenantName: "",
        currentLocation: "",
    });

    const {
        logo,
        sideImage,
        loading,
        tenant,
        invalidAccountNum,
        userTenants,
        needSelectTenant,
        authComponents,
        selectedTenantName,
        hideSignup,
        username,
        isAuthenticated,
        userNameExists,
        currentTenantName,
        currentLocation,
    } = state; // Destructure state for easier access.

    const [rememberMe, setRememberMe] = IdentityImports.useState<boolean>(false);

    const { tokens } = IdentityImports.useTheme();

    const location = window.location.host;

    if (location != currentLocation) {
        setState((prevState) => ({
            ...prevState,
            currentLocation: location,
        }));

        CallGetTenantName();

        console.log("t1: ", currentTenantName);
    }

    const services = {
        async handleSignIn(formData: IdentityTypes.SignInInput) {
            let selTenant = "";

            const subdomain = window.location.host.split(".")[0];
            console.log("Sign in subdomain: ", subdomain);
            const domainTenant = await IdentityImports.GetTenantInfoFromName(subdomain);
            if (!domainTenant?.id) {
                console.log("Sign in: No tenant found for subdomain: ", subdomain);
                if (!selectedTenantName || selectedTenantName === "") {
                    console.log(
                        "Sign in: No selected tenant found.  Getting from local storage...",
                    );
                    const selTnt = await localStorage.getItem("selectedTenant");
                    console.log("localstore: ", selTnt);
                    let tenant: any = undefined;
                    if (selTnt && selTnt !== "") {
                        const tmpTenant = await IdentityImports.GetTenantInfoFromName(selTnt);
                        if (tmpTenant?.id) {
                            setState((prevState) => ({
                                ...prevState,
                                selectedTenantName: tmpTenant.id,
                            }));

                            selTenant = tmpTenant.id;
                        }
                    }
                } else {
                    console.log("Sign in: Selected tenant: ", selectedTenantName);
                    selTenant = selectedTenantName;
                }
                console.log(needSelectTenant, selTenant);
                if (needSelectTenant === false && !window.location.host.startsWith(selTenant)) {
                    setState((prevState) => ({
                        ...prevState,
                        selectedTenantName: "",
                    }));

                    selTenant = "";
                }
            } else {
                selTenant = subdomain;
            }

            console.log("Handle Sign in....", formData);
            console.log("SELECTED TENANT local: ", selTenant);
            console.log("SELECTED TENANT State Name: ", selectedTenantName);
            console.log("local selected tenant", selectedTenant);
            try {
                const userTenants = await IdentityImports.SelectUserTenants(formData.username);

                if (
                    (!selTenant || selTenant === "") &&
                    userTenants?.data?.tenantsUsersByUsersId.items
                ) {
                    setState((prevState) => ({
                        ...prevState,
                        userTenants: userTenants.data,
                        needSelectTenant: true,
                        username: formData.username,
                    }));

                    return IdentityImports.signIn(formData);
                } else {
                    setState((prevState) => ({
                        ...prevState,
                        userTenants: {},
                        needSelectTenant: false,
                    }));
                }

                console.log(formData.username + " tenants: ", userTenants);

                const userTenantRecord = userTenants?.data?.tenantsUsersByUsersId?.items?.find(
                    (ut) => ut.tenantsId === selTenant,
                );
                if (!userTenantRecord) throw new Error("You don't have access to this tenant.");

                if (!window.location.host.startsWith(selTenant))
                    IdentityImports.GotoTenantURL(selTenant);
            } catch (e) {
                console.log("Could not get user tenants: ", e);
            }

            if (rememberMe) {
                IdentityImports.rememberDevice()
                    .then(() => {
                        console.log("Remembered device.");
                    })
                    .catch((err) => {
                        console.log("Error remembering device: ", err);
                    });
            }

            return IdentityImports.signIn(formData);
        },

        async validateCustomSignUp(formData: any) {
            if (formData.ccustno) {
                accountNum = formData.ccustno;

                if (invalidAccountNum) {
                    return {
                        ccustno: "You are not authorized to access this account number.",
                    };
                }
            }
        },

        async handleSignUp(formData: IdentityTypes.SignUpInput) {
            let { username, password, options } = formData;
            let selTenant = "";

            if (username === "" || password === "" || accountNum === "")
                throw new Error("You must fill in all of the fields.");

            username = username.toLowerCase();
            console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa username: ", username);
            console.log("Options: ", options);
            console.log("Validation Data", options?.validationData);
            console.log("AccountNum: ", accountNum);

            const subdomain = window.location.host.split(".")[0];
            console.log("Sign in subdomain: ", subdomain);
            const domainTenant = await IdentityImports.GetTenantInfoFromName(subdomain);
            if (!domainTenant?.id) {
                throw new Error("Invalid subdomain, cannot sign up.");
            } else {
                selTenant = subdomain;
            }

            await IdentityImports.fetchSecrets();
            const token = await IdentityImports.API_Authenticate();
            const bypass = accountNum.startsWith("PrsmOvrd:");
            if (bypass) accountNum = accountNum.replace("PrsmOvrd:", "");
            const regNewUser = await IdentityImports.API_RegisterNewUser(
                token,
                accountNum,
                username,
                bypass,
            );

            if (!regNewUser) throw new Error("Error registering new user.");

            console.log("aaaaASDFASDFASDFASDFASDFASDFASDFASDFASDFASDF", regNewUser);
            if (!regNewUser.IsSuccessful)
                throw new Error(
                    "Error registering new user.  " +
                        regNewUser.ErrorCode +
                        ": " +
                        regNewUser.Error,
                );

            try {
                const ret = await IdentityImports.signUp({
                    username,
                    password,
                    options,
                });
                console.log("Sign Up return: ", ret);
                if (ret.isSignUpComplete) {
                    registerUserWithCustomerNumber(formData.username, selTenant);
                }

                return ret;
            } catch (err: any) {
                console.log("Error message: ", err.message);
                if (err.name === "UsernameExistsException") {
                    setState((prevState) => ({
                        ...prevState,
                        userNameExists: true,
                    }));
                    registerUserWithCustomerNumber(formData.username, selTenant);
                    console.log("User Name already exists.");
                    throw new Error(
                        "User Name already exists, but customer has now been linked to user.  Please sign in using your existing password, or choose forgot password if you have forgotten your password.",
                    );
                }

                console.log("Sign Up error: ", err);
                throw err;
            }
        },
    };

    async function registerUserWithCustomerNumber(username: string, selTenant: string) {
        console.log("t1");
        const allTenantsUsers = await IdentityImports.SelectAllTenantsUsers();
        console.log("All tenants users: ", allTenantsUsers);
        const userTenants = await IdentityImports.SelectUserTenants(username);
        console.log(username + " tenants: ", userTenants);
        const userTenantRecord = userTenants?.data?.tenantsUsersByUsersId?.items?.find(
            (ut) => ut.tenantsId === selTenant,
        );
        console.log("User Tenant Record: ", userTenantRecord);
        if (!userTenantRecord || userTenantRecord.usersId !== username.trim().toLowerCase()) {
            console.log("Inserting user");
            const res = await IdentityImports.InsertUser(selTenant, username);
            if (!res) console.log("Insert user returned nothing.");
            else {
                console.log("Insert user result:", res);
            }
        }
        console.log("t2");
    }

    const [authServices, setAuthServices] = IdentityImports.useState(services);

    let accountNum = "";

    const setSelectedTenant = async (tenantId: string) => {
        localStorage.removeItem("selectedTenant");
        if (tenantId) {
            console.log("Getting selected tenant info for " + tenantId);
            const tenantObj = await IdentityImports.GetTenantInfoFromName(tenantId);
            if (tenantObj) {
                console.log("Got selected tenant info: Setting tenant...", tenantObj);
                setState((prevState) => ({
                    ...prevState,
                    tenant: { ...prevState.tenant, ...tenantObj, selectedTenant: tenantObj },
                    selectedTenantName: tenantId,
                }));
                localStorage.setItem("selectedTenant", tenantId);
            }
        }
    };
    const handleTenantChange = (event: IdentityTypes.SelectChangeEvent) => {
        console.log("Selected Tenant changed. ", event.target);
        setSelectedTenant(event.target.value);
    };

    IdentityImports.useEffect(() => {
        if (!currentTenantName && currentTenantName !== "" && !loadingTenantName)
            getTenantNameWithWait();
    });

    const getTenantNameWithWait = () => {
        setLoadingTenantName(true);
        for (let i = 0; i < 10; i++) {
            if (currentTenantName !== "") {
                break;
            }
            (async () => await new Promise((resolve) => setTimeout(resolve, 500)))();
        }
        setLoadingTenantName(false);
        return currentTenantName;
    };

    const components = {
        Header: () => (
            <AmplifyFlow.Header
                logo={state.logo}
                companyName={IdentityImports.GetCompanyName()}
            />
        ),
        SignIn: {
            Header: AmplifyFlow.SignIn.Header,
            Footer: () => (
                <AmplifyFlow.SignIn.Footer
                    rememberMe={rememberMe}
                    setRememberMe={setRememberMe}
                />
            ),
        },
        SignUp: {
            Header: AmplifyFlow.SignUp.Header,
            FormFields: AmplifyFlow.SignUp.FormFields,
            Footer: AmplifyFlow.SignUp.Footer,
        },
        ForgotPassword: {
            Header: AmplifyFlow.ForgotPassword.Header,
            Footer: AmplifyFlow.ForgotPassword.Footer,
        },
        Footer: AmplifyFlow.Footer,
    };

    IdentityImports.useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            authComponents: components,
            authServices: services,
        }));
    }, [needSelectTenant]);

    let selectedTenant: any = null;
    IdentityImports.useEffect(() => {
        console.log("Tenant useEffect: ", tenant);
        selectedTenant = tenant;
    }, [tenant]);

    IdentityImports.useEffect(() => {
        console.log("Loading authenticate...");
        setState((prevState) => ({
            ...prevState,
            loading: true,
        }));

        try {
            IdentityImports.setAWSTokens();
            getTenantData();
        } catch (err) {
            console.log("Authenticate: useEffect[] error: ", err);
        } finally {
            setState((prevState) => ({
                ...prevState,
                loading: false,
            }));
        }
    }, []);

    IdentityImports.useEffect(() => {
        if (loading) {
            setState((prevState) => ({
                ...prevState,
                authComponents: components,
                authServices: services,
            }));

            // setState is asynchronous. Call getTenantData in the next event loop tick to ensure it executes after the state updates.
            setTimeout(() => {
                getTenantData();
            }, 0);
        }
    }, [selectedTenantName, loading, components, services]);

    IdentityImports.useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            authComponents: components,
        }));
    }, [logo]);

    const getTenantData = async () => {
        console.log("Getting tenant data...  Current tenant: ", tenant);

        if (!selectedTenantName || selectedTenantName === "") {
            const tenantObj = await IdentityImports.GetTenantInfo();
            if (tenantObj) {
                console.log("UseEffect... Setting tenant...", tenantObj);
                setState((prevState) => ({
                    ...prevState,
                    currentTenantName: tenantObj.id,
                    selectedTenant: tenantObj.id,
                    selectedTenantName: tenantObj.id,
                    hideSignup: false,
                }));
            }
        }
        const updateImages = async () => {
            const logo = await IdentityImports.GetCompanyLogo();
            const sideImage = await IdentityImports.GetCompanySideImage();
            setState((prevState) => ({
                ...prevState,
                logo: logo,
                sideImage: sideImage,
            }));
        };

        updateImages();
    };

    function onChooseTenant(tenantName: string): void {
        setSelectedTenant(tenantName);
        localStorage.setItem("selectedTenant", tenantName);
        console.log("onChooseTenant: ", tenantName);
        IdentityImports.GotoTenantURL(tenantName);
    }

    const SignedIn = () => {
        setState((prevState) => ({
            ...prevState,
            selectedTenantName: "",
        }));

        return true;
    };

    const signOutUser = async () => {
        localStorage.removeItem("selectedTenant");

        setState((prevState) => ({
            ...prevState,
            selectedTenantName: "",
        }));

        signOut && signOut({ global: true });

        ClearAllCookies();
    };

    async function CallGetTenantName() {
        if ((!currentTenantName || currentTenantName === "") && !loading) {
            setState((prevState) => ({
                ...prevState,
                loading: true,
            }));
        }

        try {
            const tenantObj = await IdentityImports.GetTenantInfo();
            if (tenantObj) {
                setState((prevState) => ({
                    ...prevState,
                    currentTenantName: tenantObj.id,
                }));
            } else {
                console.log("Authenticate: CallGetTenantName: No Tenant Found");
                setState((prevState) => ({ ...prevState, loading: false }));
            }
        } catch (err) {
            console.log("Authenticate: CallGetTenantName: Error: ", err);
            setState((prevState) => ({ ...prevState, loading: false }));
        }
    }

    IdentityImports.useEffect(() => {
        CallGetTenantName();
        console.log("Location Changed: ", location);
    }, [location]);
    const [loadingTenantName, setLoadingTenantName] = IdentityImports.useState(false);

    const { authStatus } = IdentityImports.useAuthenticator((context) => [context.authStatus]);
    const auth = IdentityImports.useAuthenticator((context) => [context.user]);
    user = auth.user;
    signOut = auth.signOut;
    console.log("authStatus: ", authStatus);
    console.log("user: ", user);
    console.log("444 TenantName: ", currentTenantName);

    return (
        <>
            {loading ?
                <IdentityImports.LoadingSpinner message='Loading...' />
            :   <IdentityImports.Stack
                    width={"100vw"}
                    height={"100vh"}
                    direction='row'
                    spacing={0}
                    justifyContent={"space-between"}
                >
                    {authStatus === "authenticated" && !loadingTenantName ? null : (
                        <IdentityImports.Hidden smDown>
                            <IdentityImports.View height='100vh'>
                                <IdentityImports.Image
                                    alt='Image'
                                    src={sideImage}
                                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                        if (sideImage !== "")
                                            setState((prevState) => ({
                                                ...prevState,
                                                sideImage: sideImage,
                                            }));
                                    }}
                                    maxWidth='63vw'
                                    maxHeight='100vh'
                                />
                            </IdentityImports.View>
                        </IdentityImports.Hidden>
                    )}
                    <IdentityImports.Box
                        width='100%'
                        sx={{ backgroundColor: "transparent" }}
                        justifyContent='center'
                    >
                        <IdentityImports.ThemeProvider theme={IdentityTheme}>
                            <IdentityImports.Authenticator
                                components={components}
                                services={services}
                                formFields={formFields}
                                hideSignUp={hideSignup}
                            >
                                {({ signOut, user }) =>
                                    authStatus !== "authenticated" ? <div>Loading...</div>
                                    : currentTenantName && currentTenantName != "" ?
                                        <IdentityImports.App
                                            signout={signOutUser}
                                            user={user}
                                        />
                                    : !loadingTenantName ?
                                        <IdentityImports.Delayed waitBeforeShow={2000}>
                                            <IdentityImports.TenantChooser
                                                username={
                                                    auth.user?.signInDetails?.loginId ?
                                                        auth.user?.signInDetails?.loginId
                                                    :   IdentityImports.GetUserName()
                                                }
                                                onChooseTenant={onChooseTenant}
                                                onSignOut={() => signOutUser()}
                                            />
                                        </IdentityImports.Delayed>
                                    :   <></>
                                }
                            </IdentityImports.Authenticator>
                        </IdentityImports.ThemeProvider>
                    </IdentityImports.Box>
                </IdentityImports.Stack>
            }
        </>
    );
};

export default Authenticate;
