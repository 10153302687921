import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, IconButton, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_Authenticate, API_GetTicketReport, GetUserName } from "../services/GetInvoiceData";
import "../styles.css";
import { GreenButton, WhiteButton } from "./Buttons";
import { CustomDivider } from "./CustomDivider";
import LoadingSpinner from "./LoadingSpinner";
import { useIsMobile } from "helpers/CustomHooks";

type Props = {
    invoicenums: string;
    openDialog: boolean;
};

const InvoicePaymentComplete: React.FC<Props> = ({ invoicenums, openDialog }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    const isMobile = useIsMobile();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {};

        setIsLoading(true);
        fetchData();
        setDialogOpen(openDialog);
        setIsLoading(false);
    }, [invoicenums, openDialog]);

    useEffect(() => {
        if (!dialogOpen && !isLoading) {
            goBackToInvoices();
        }
    }, [dialogOpen]);

    const goBackToInvoices = () => {
        navigate("/viewinvoices");
    };

    return (
        <Dialog
            open={dialogOpen}
            onClose={goBackToInvoices}
            sx={{ width: isMobile ? "375px" : "665px", m: "auto" }}
        >
            <div className='float-right'>
                <IconButton
                    className='float-right'
                    onClick={() => setDialogOpen(false)}
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <Stack
                spacing={1}
                direction='column'
                sx={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    marginBottom: "20px",
                    marginTop: "1px",
                }}
                alignItems='center'
            >
                <div>
                    <CheckCircleIcon sx={{ fontSize: 80, color: "#58B55D" }} />
                </div>
                <div className='checkout_info_label_bold'>Payent Successful!</div>
                <div className='order_complete_text'>
                    Your payment for Invoices has been received on {""}.
                </div>
                <Box
                    sx={{
                        textAlign: "center",
                        border: "1px dotted #DADCE0",
                        backgroundColor: "#F6F7F9",
                        width: "326px",
                        radius: "4px",
                    }}
                >
                    <div className='order_complete_text'>Invoice #</div>
                    <div className='order_complete_order_id'>{invoicenums}</div>
                </Box>
                <div className='order_complete_text'>
                    Your balance will be updated in 24-36 hours.
                </div>

                <GreenButton
                    variant='outlined'
                    onClick={() => goBackToInvoices()}
                >
                    Pay Another Invoice
                </GreenButton>
            </Stack>
        </Dialog>
    );
};

export default InvoicePaymentComplete;
