import { Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

export type Props = {
    message: string;
    onGoBack: () => void;
};

const ErrorDisplay: React.FC<Props> = (props) => {
    const ColoredLine = () => (
        <hr
            style={{
                color: "black",
                backgroundColor: "black",
                height: 2,
            }}
        />
    );

    return (
        <Card>
            <CardContent>
                <Alert
                    variant='filled'
                    severity='error'
                >
                    {props.message}
                </Alert>
                <br />
                <br />
                <Button
                    variant='contained'
                    onClick={() => {
                        props.onGoBack();
                    }}
                >
                    Go Back
                </Button>
            </CardContent>
        </Card>
    );
};

export default ErrorDisplay;
