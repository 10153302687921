import { styled } from "@mui/material";

const MyAdminPageContainer = styled("div")(({ theme }) => ({
    // Add styles here.
}));

const MyAdminPage = () => (
    <MyAdminPageContainer>
        <h1>My Admin Settings</h1>
    </MyAdminPageContainer>
);

export default MyAdminPage;
