import { styled } from "@mui/material";

const FAQsPageContainer = styled("div")(({ theme }) => ({
    // Add styles here.
}));

const FAQsPage = () => (
    <FAQsPageContainer>
        <h1>Frequently Asked Questions</h1>
    </FAQsPageContainer>
);

export default FAQsPage;
