/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Autocomplete,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Icon,
  ScrollView,
  SwitchField,
  Text,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { listClients, listHubs, listUsers } from "../graphql/queries";
import {
  createTenants,
  createTenantsUsers,
  updateHubs,
} from "../graphql/mutations";
const client = generateClient();
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function NewForm2(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    id: "",
    subdomain: "",
    usesHubs: false,
    name: "",
    url: "",
    uniqueid: "",
    createdby: "",
    logo: "",
    companyname: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    hubs: [],
    users: [],
    linkedclient: undefined,
    clientsTenantsCompanycode: undefined,
  };
  const [id, setId] = React.useState(initialValues.id);
  const [subdomain, setSubdomain] = React.useState(initialValues.subdomain);
  const [usesHubs, setUsesHubs] = React.useState(initialValues.usesHubs);
  const [name, setName] = React.useState(initialValues.name);
  const [url, setUrl] = React.useState(initialValues.url);
  const [uniqueid, setUniqueid] = React.useState(initialValues.uniqueid);
  const [createdby, setCreatedby] = React.useState(initialValues.createdby);
  const [logo, setLogo] = React.useState(initialValues.logo);
  const [companyname, setCompanyname] = React.useState(
    initialValues.companyname
  );
  const [address1, setAddress1] = React.useState(initialValues.address1);
  const [address2, setAddress2] = React.useState(initialValues.address2);
  const [city, setCity] = React.useState(initialValues.city);
  const [state, setState] = React.useState(initialValues.state);
  const [zip, setZip] = React.useState(initialValues.zip);
  const [phone, setPhone] = React.useState(initialValues.phone);
  const [email, setEmail] = React.useState(initialValues.email);
  const [hubs, setHubs] = React.useState(initialValues.hubs);
  const [hubsLoading, setHubsLoading] = React.useState(false);
  const [hubsRecords, setHubsRecords] = React.useState([]);
  const [users, setUsers] = React.useState(initialValues.users);
  const [usersLoading, setUsersLoading] = React.useState(false);
  const [usersRecords, setUsersRecords] = React.useState([]);
  const [linkedclient, setLinkedclient] = React.useState(
    initialValues.linkedclient
  );
  const [linkedclientLoading, setLinkedclientLoading] = React.useState(false);
  const [linkedclientRecords, setLinkedclientRecords] = React.useState([]);
  const [clientsTenantsCompanycode, setClientsTenantsCompanycode] =
    React.useState(initialValues.clientsTenantsCompanycode);
  const [
    clientsTenantsCompanycodeLoading,
    setClientsTenantsCompanycodeLoading,
  ] = React.useState(false);
  const [
    clientsTenantsCompanycodeRecords,
    setClientsTenantsCompanycodeRecords,
  ] = React.useState([]);
  const [
    selectedClientsTenantsCompanycodeRecords,
    setSelectedClientsTenantsCompanycodeRecords,
  ] = React.useState([]);
  const autocompleteLength = 10;
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setId(initialValues.id);
    setSubdomain(initialValues.subdomain);
    setUsesHubs(initialValues.usesHubs);
    setName(initialValues.name);
    setUrl(initialValues.url);
    setUniqueid(initialValues.uniqueid);
    setCreatedby(initialValues.createdby);
    setLogo(initialValues.logo);
    setCompanyname(initialValues.companyname);
    setAddress1(initialValues.address1);
    setAddress2(initialValues.address2);
    setCity(initialValues.city);
    setState(initialValues.state);
    setZip(initialValues.zip);
    setPhone(initialValues.phone);
    setEmail(initialValues.email);
    setHubs(initialValues.hubs);
    setCurrentHubsValue(undefined);
    setCurrentHubsDisplayValue("");
    setUsers(initialValues.users);
    setCurrentUsersValue(undefined);
    setCurrentUsersDisplayValue("");
    setLinkedclient(initialValues.linkedclient);
    setCurrentLinkedclientValue(undefined);
    setCurrentLinkedclientDisplayValue("");
    setClientsTenantsCompanycode(initialValues.clientsTenantsCompanycode);
    setCurrentClientsTenantsCompanycodeValue(undefined);
    setCurrentClientsTenantsCompanycodeDisplayValue("");
    setErrors({});
  };
  const [currentHubsDisplayValue, setCurrentHubsDisplayValue] =
    React.useState("");
  const [currentHubsValue, setCurrentHubsValue] = React.useState(undefined);
  const hubsRef = React.createRef();
  const [currentUsersDisplayValue, setCurrentUsersDisplayValue] =
    React.useState("");
  const [currentUsersValue, setCurrentUsersValue] = React.useState(undefined);
  const usersRef = React.createRef();
  const [currentLinkedclientDisplayValue, setCurrentLinkedclientDisplayValue] =
    React.useState("");
  const [currentLinkedclientValue, setCurrentLinkedclientValue] =
    React.useState(undefined);
  const linkedclientRef = React.createRef();
  const [
    currentClientsTenantsCompanycodeDisplayValue,
    setCurrentClientsTenantsCompanycodeDisplayValue,
  ] = React.useState("");
  const [
    currentClientsTenantsCompanycodeValue,
    setCurrentClientsTenantsCompanycodeValue,
  ] = React.useState(undefined);
  const clientsTenantsCompanycodeRef = React.createRef();
  const getIDValue = {
    hubs: (r) => JSON.stringify({ id: r?.id }),
    users: (r) => JSON.stringify({ id: r?.id }),
    linkedclient: (r) => JSON.stringify({ companycode: r?.companycode }),
  };
  const hubsIdSet = new Set(
    Array.isArray(hubs)
      ? hubs.map((r) => getIDValue.hubs?.(r))
      : getIDValue.hubs?.(hubs)
  );
  const usersIdSet = new Set(
    Array.isArray(users)
      ? users.map((r) => getIDValue.users?.(r))
      : getIDValue.users?.(users)
  );
  const linkedclientIdSet = new Set(
    Array.isArray(linkedclient)
      ? linkedclient.map((r) => getIDValue.linkedclient?.(r))
      : getIDValue.linkedclient?.(linkedclient)
  );
  const getDisplayValue = {
    hubs: (r) => `${r?.chubno ? r?.chubno + " - " : ""}${r?.id}`,
    users: (r) => `${r?.username ? r?.username + " - " : ""}${r?.id}`,
    linkedclient: (r) => `${r?.name ? r?.name + " - " : ""}${r?.companycode}`,
    clientsTenantsCompanycode: (r) =>
      `${r?.name ? r?.name + " - " : ""}${r?.companycode}`,
  };
  const validations = {
    id: [{ type: "Required" }],
    subdomain: [{ type: "Required" }],
    usesHubs: [{ type: "Required" }],
    name: [{ type: "Required" }],
    url: [{ type: "Required" }],
    uniqueid: [{ type: "Required" }],
    createdby: [{ type: "Required" }],
    logo: [],
    companyname: [],
    address1: [],
    address2: [],
    city: [],
    state: [],
    zip: [],
    phone: [],
    email: [],
    hubs: [],
    users: [],
    linkedclient: [],
    clientsTenantsCompanycode: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  const fetchHubsRecords = async (value) => {
    setHubsLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ chubno: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listHubs.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listHubs?.items;
      var loaded = result.filter(
        (item) => !hubsIdSet.has(getIDValue.hubs?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setHubsRecords(newOptions.slice(0, autocompleteLength));
    setHubsLoading(false);
  };
  const fetchUsersRecords = async (value) => {
    setUsersLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [{ username: { contains: value } }, { id: { contains: value } }],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listUsers.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listUsers?.items;
      var loaded = result.filter(
        (item) => !usersIdSet.has(getIDValue.users?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setUsersRecords(newOptions.slice(0, autocompleteLength));
    setUsersLoading(false);
  };
  const fetchLinkedclientRecords = async (value) => {
    setLinkedclientLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [
            { name: { contains: value } },
            { companycode: { contains: value } },
          ],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listClients.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listClients?.items;
      var loaded = result.filter(
        (item) => !linkedclientIdSet.has(getIDValue.linkedclient?.(item))
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setLinkedclientRecords(newOptions.slice(0, autocompleteLength));
    setLinkedclientLoading(false);
  };
  const fetchClientsTenantsCompanycodeRecords = async (value) => {
    setClientsTenantsCompanycodeLoading(true);
    const newOptions = [];
    let newNext = "";
    while (newOptions.length < autocompleteLength && newNext != null) {
      const variables = {
        limit: autocompleteLength * 5,
        filter: {
          or: [
            { name: { contains: value } },
            { companycode: { contains: value } },
          ],
        },
      };
      if (newNext) {
        variables["nextToken"] = newNext;
      }
      const result = (
        await client.graphql({
          query: listClients.replaceAll("__typename", ""),
          variables,
        })
      )?.data?.listClients?.items;
      var loaded = result.filter(
        (item) => clientsTenantsCompanycode !== item.id
      );
      newOptions.push(...loaded);
      newNext = result.nextToken;
    }
    setClientsTenantsCompanycodeRecords(
      newOptions.slice(0, autocompleteLength)
    );
    setClientsTenantsCompanycodeLoading(false);
  };
  React.useEffect(() => {
    fetchHubsRecords("");
    fetchUsersRecords("");
    fetchLinkedclientRecords("");
    fetchClientsTenantsCompanycodeRecords("");
  }, []);
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          id,
          subdomain,
          usesHubs,
          name,
          url,
          uniqueid,
          createdby,
          logo,
          companyname,
          address1,
          address2,
          city,
          state,
          zip,
          phone,
          email,
          hubs,
          users,
          linkedclient,
          clientsTenantsCompanycode,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(
                    fieldName,
                    item,
                    getDisplayValue[fieldName]
                  )
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(
                fieldName,
                modelFields[fieldName],
                getDisplayValue[fieldName]
              )
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          const modelFieldsToSave = {
            id: modelFields.id,
            subdomain: modelFields.subdomain,
            usesHubs: modelFields.usesHubs,
            name: modelFields.name,
            url: modelFields.url,
            uniqueid: modelFields.uniqueid,
            createdby: modelFields.createdby,
            logo: modelFields.logo,
            companyname: modelFields.companyname,
            address1: modelFields.address1,
            address2: modelFields.address2,
            city: modelFields.city,
            state: modelFields.state,
            zip: modelFields.zip,
            phone: modelFields.phone,
            email: modelFields.email,
            tenantsLinkedclientCompanycode:
              modelFields?.linkedclient?.companycode,
            clientsTenantsCompanycode: modelFields.clientsTenantsCompanycode,
          };
          const tenants = (
            await client.graphql({
              query: createTenants.replaceAll("__typename", ""),
              variables: {
                input: {
                  ...modelFieldsToSave,
                },
              },
            })
          )?.data?.createTenants;
          const promises = [];
          promises.push(
            ...hubs.reduce((promises, original) => {
              promises.push(
                client.graphql({
                  query: updateHubs.replaceAll("__typename", ""),
                  variables: {
                    input: {
                      id: original.id,
                      tenantsHubsId: tenants.id,
                    },
                  },
                })
              );
              return promises;
            }, [])
          );
          promises.push(
            ...users.reduce((promises, users) => {
              promises.push(
                client.graphql({
                  query: createTenantsUsers.replaceAll("__typename", ""),
                  variables: {
                    input: {
                      tenantsId: tenants.id,
                      usersId: users.id,
                    },
                  },
                })
              );
              return promises;
            }, [])
          );
          await Promise.all(promises);
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "NewForm2")}
      {...rest}
    >
      <TextField
        label="Id"
        isRequired={true}
        isReadOnly={false}
        value={id}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              id: value,
              subdomain,
              usesHubs,
              name,
              url,
              uniqueid,
              createdby,
              logo,
              companyname,
              address1,
              address2,
              city,
              state,
              zip,
              phone,
              email,
              hubs,
              users,
              linkedclient,
              clientsTenantsCompanycode,
            };
            const result = onChange(modelFields);
            value = result?.id ?? value;
          }
          if (errors.id?.hasError) {
            runValidationTasks("id", value);
          }
          setId(value);
        }}
        onBlur={() => runValidationTasks("id", id)}
        errorMessage={errors.id?.errorMessage}
        hasError={errors.id?.hasError}
        {...getOverrideProps(overrides, "id")}
      ></TextField>
      <TextField
        label="Subdomain"
        isRequired={true}
        isReadOnly={false}
        value={subdomain}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              id,
              subdomain: value,
              usesHubs,
              name,
              url,
              uniqueid,
              createdby,
              logo,
              companyname,
              address1,
              address2,
              city,
              state,
              zip,
              phone,
              email,
              hubs,
              users,
              linkedclient,
              clientsTenantsCompanycode,
            };
            const result = onChange(modelFields);
            value = result?.subdomain ?? value;
          }
          if (errors.subdomain?.hasError) {
            runValidationTasks("subdomain", value);
          }
          setSubdomain(value);
        }}
        onBlur={() => runValidationTasks("subdomain", subdomain)}
        errorMessage={errors.subdomain?.errorMessage}
        hasError={errors.subdomain?.hasError}
        {...getOverrideProps(overrides, "subdomain")}
      ></TextField>
      <SwitchField
        label="Uses hubs"
        defaultChecked={false}
        isDisabled={false}
        isChecked={usesHubs}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              id,
              subdomain,
              usesHubs: value,
              name,
              url,
              uniqueid,
              createdby,
              logo,
              companyname,
              address1,
              address2,
              city,
              state,
              zip,
              phone,
              email,
              hubs,
              users,
              linkedclient,
              clientsTenantsCompanycode,
            };
            const result = onChange(modelFields);
            value = result?.usesHubs ?? value;
          }
          if (errors.usesHubs?.hasError) {
            runValidationTasks("usesHubs", value);
          }
          setUsesHubs(value);
        }}
        onBlur={() => runValidationTasks("usesHubs", usesHubs)}
        errorMessage={errors.usesHubs?.errorMessage}
        hasError={errors.usesHubs?.hasError}
        {...getOverrideProps(overrides, "usesHubs")}
      ></SwitchField>
      <TextField
        label="Name"
        isRequired={true}
        isReadOnly={false}
        value={name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              id,
              subdomain,
              usesHubs,
              name: value,
              url,
              uniqueid,
              createdby,
              logo,
              companyname,
              address1,
              address2,
              city,
              state,
              zip,
              phone,
              email,
              hubs,
              users,
              linkedclient,
              clientsTenantsCompanycode,
            };
            const result = onChange(modelFields);
            value = result?.name ?? value;
          }
          if (errors.name?.hasError) {
            runValidationTasks("name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("name", name)}
        errorMessage={errors.name?.errorMessage}
        hasError={errors.name?.hasError}
        {...getOverrideProps(overrides, "name")}
      ></TextField>
      <TextField
        label="Url"
        isRequired={true}
        isReadOnly={false}
        value={url}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              id,
              subdomain,
              usesHubs,
              name,
              url: value,
              uniqueid,
              createdby,
              logo,
              companyname,
              address1,
              address2,
              city,
              state,
              zip,
              phone,
              email,
              hubs,
              users,
              linkedclient,
              clientsTenantsCompanycode,
            };
            const result = onChange(modelFields);
            value = result?.url ?? value;
          }
          if (errors.url?.hasError) {
            runValidationTasks("url", value);
          }
          setUrl(value);
        }}
        onBlur={() => runValidationTasks("url", url)}
        errorMessage={errors.url?.errorMessage}
        hasError={errors.url?.hasError}
        {...getOverrideProps(overrides, "url")}
      ></TextField>
      <TextField
        label="Uniqueid"
        isRequired={true}
        isReadOnly={false}
        value={uniqueid}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              id,
              subdomain,
              usesHubs,
              name,
              url,
              uniqueid: value,
              createdby,
              logo,
              companyname,
              address1,
              address2,
              city,
              state,
              zip,
              phone,
              email,
              hubs,
              users,
              linkedclient,
              clientsTenantsCompanycode,
            };
            const result = onChange(modelFields);
            value = result?.uniqueid ?? value;
          }
          if (errors.uniqueid?.hasError) {
            runValidationTasks("uniqueid", value);
          }
          setUniqueid(value);
        }}
        onBlur={() => runValidationTasks("uniqueid", uniqueid)}
        errorMessage={errors.uniqueid?.errorMessage}
        hasError={errors.uniqueid?.hasError}
        {...getOverrideProps(overrides, "uniqueid")}
      ></TextField>
      <TextField
        label="Createdby"
        isRequired={true}
        isReadOnly={false}
        value={createdby}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              id,
              subdomain,
              usesHubs,
              name,
              url,
              uniqueid,
              createdby: value,
              logo,
              companyname,
              address1,
              address2,
              city,
              state,
              zip,
              phone,
              email,
              hubs,
              users,
              linkedclient,
              clientsTenantsCompanycode,
            };
            const result = onChange(modelFields);
            value = result?.createdby ?? value;
          }
          if (errors.createdby?.hasError) {
            runValidationTasks("createdby", value);
          }
          setCreatedby(value);
        }}
        onBlur={() => runValidationTasks("createdby", createdby)}
        errorMessage={errors.createdby?.errorMessage}
        hasError={errors.createdby?.hasError}
        {...getOverrideProps(overrides, "createdby")}
      ></TextField>
      <TextField
        label="Logo"
        isRequired={false}
        isReadOnly={false}
        value={logo}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              id,
              subdomain,
              usesHubs,
              name,
              url,
              uniqueid,
              createdby,
              logo: value,
              companyname,
              address1,
              address2,
              city,
              state,
              zip,
              phone,
              email,
              hubs,
              users,
              linkedclient,
              clientsTenantsCompanycode,
            };
            const result = onChange(modelFields);
            value = result?.logo ?? value;
          }
          if (errors.logo?.hasError) {
            runValidationTasks("logo", value);
          }
          setLogo(value);
        }}
        onBlur={() => runValidationTasks("logo", logo)}
        errorMessage={errors.logo?.errorMessage}
        hasError={errors.logo?.hasError}
        {...getOverrideProps(overrides, "logo")}
      ></TextField>
      <TextField
        label="Companyname"
        isRequired={false}
        isReadOnly={false}
        value={companyname}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              id,
              subdomain,
              usesHubs,
              name,
              url,
              uniqueid,
              createdby,
              logo,
              companyname: value,
              address1,
              address2,
              city,
              state,
              zip,
              phone,
              email,
              hubs,
              users,
              linkedclient,
              clientsTenantsCompanycode,
            };
            const result = onChange(modelFields);
            value = result?.companyname ?? value;
          }
          if (errors.companyname?.hasError) {
            runValidationTasks("companyname", value);
          }
          setCompanyname(value);
        }}
        onBlur={() => runValidationTasks("companyname", companyname)}
        errorMessage={errors.companyname?.errorMessage}
        hasError={errors.companyname?.hasError}
        {...getOverrideProps(overrides, "companyname")}
      ></TextField>
      <TextField
        label="Address1"
        isRequired={false}
        isReadOnly={false}
        value={address1}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              id,
              subdomain,
              usesHubs,
              name,
              url,
              uniqueid,
              createdby,
              logo,
              companyname,
              address1: value,
              address2,
              city,
              state,
              zip,
              phone,
              email,
              hubs,
              users,
              linkedclient,
              clientsTenantsCompanycode,
            };
            const result = onChange(modelFields);
            value = result?.address1 ?? value;
          }
          if (errors.address1?.hasError) {
            runValidationTasks("address1", value);
          }
          setAddress1(value);
        }}
        onBlur={() => runValidationTasks("address1", address1)}
        errorMessage={errors.address1?.errorMessage}
        hasError={errors.address1?.hasError}
        {...getOverrideProps(overrides, "address1")}
      ></TextField>
      <TextField
        label="Address2"
        isRequired={false}
        isReadOnly={false}
        value={address2}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              id,
              subdomain,
              usesHubs,
              name,
              url,
              uniqueid,
              createdby,
              logo,
              companyname,
              address1,
              address2: value,
              city,
              state,
              zip,
              phone,
              email,
              hubs,
              users,
              linkedclient,
              clientsTenantsCompanycode,
            };
            const result = onChange(modelFields);
            value = result?.address2 ?? value;
          }
          if (errors.address2?.hasError) {
            runValidationTasks("address2", value);
          }
          setAddress2(value);
        }}
        onBlur={() => runValidationTasks("address2", address2)}
        errorMessage={errors.address2?.errorMessage}
        hasError={errors.address2?.hasError}
        {...getOverrideProps(overrides, "address2")}
      ></TextField>
      <TextField
        label="City"
        isRequired={false}
        isReadOnly={false}
        value={city}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              id,
              subdomain,
              usesHubs,
              name,
              url,
              uniqueid,
              createdby,
              logo,
              companyname,
              address1,
              address2,
              city: value,
              state,
              zip,
              phone,
              email,
              hubs,
              users,
              linkedclient,
              clientsTenantsCompanycode,
            };
            const result = onChange(modelFields);
            value = result?.city ?? value;
          }
          if (errors.city?.hasError) {
            runValidationTasks("city", value);
          }
          setCity(value);
        }}
        onBlur={() => runValidationTasks("city", city)}
        errorMessage={errors.city?.errorMessage}
        hasError={errors.city?.hasError}
        {...getOverrideProps(overrides, "city")}
      ></TextField>
      <TextField
        label="State"
        isRequired={false}
        isReadOnly={false}
        value={state}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              id,
              subdomain,
              usesHubs,
              name,
              url,
              uniqueid,
              createdby,
              logo,
              companyname,
              address1,
              address2,
              city,
              state: value,
              zip,
              phone,
              email,
              hubs,
              users,
              linkedclient,
              clientsTenantsCompanycode,
            };
            const result = onChange(modelFields);
            value = result?.state ?? value;
          }
          if (errors.state?.hasError) {
            runValidationTasks("state", value);
          }
          setState(value);
        }}
        onBlur={() => runValidationTasks("state", state)}
        errorMessage={errors.state?.errorMessage}
        hasError={errors.state?.hasError}
        {...getOverrideProps(overrides, "state")}
      ></TextField>
      <TextField
        label="Zip"
        isRequired={false}
        isReadOnly={false}
        value={zip}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              id,
              subdomain,
              usesHubs,
              name,
              url,
              uniqueid,
              createdby,
              logo,
              companyname,
              address1,
              address2,
              city,
              state,
              zip: value,
              phone,
              email,
              hubs,
              users,
              linkedclient,
              clientsTenantsCompanycode,
            };
            const result = onChange(modelFields);
            value = result?.zip ?? value;
          }
          if (errors.zip?.hasError) {
            runValidationTasks("zip", value);
          }
          setZip(value);
        }}
        onBlur={() => runValidationTasks("zip", zip)}
        errorMessage={errors.zip?.errorMessage}
        hasError={errors.zip?.hasError}
        {...getOverrideProps(overrides, "zip")}
      ></TextField>
      <TextField
        label="Phone"
        isRequired={false}
        isReadOnly={false}
        value={phone}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              id,
              subdomain,
              usesHubs,
              name,
              url,
              uniqueid,
              createdby,
              logo,
              companyname,
              address1,
              address2,
              city,
              state,
              zip,
              phone: value,
              email,
              hubs,
              users,
              linkedclient,
              clientsTenantsCompanycode,
            };
            const result = onChange(modelFields);
            value = result?.phone ?? value;
          }
          if (errors.phone?.hasError) {
            runValidationTasks("phone", value);
          }
          setPhone(value);
        }}
        onBlur={() => runValidationTasks("phone", phone)}
        errorMessage={errors.phone?.errorMessage}
        hasError={errors.phone?.hasError}
        {...getOverrideProps(overrides, "phone")}
      ></TextField>
      <TextField
        label="Email"
        isRequired={false}
        isReadOnly={false}
        value={email}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              id,
              subdomain,
              usesHubs,
              name,
              url,
              uniqueid,
              createdby,
              logo,
              companyname,
              address1,
              address2,
              city,
              state,
              zip,
              phone,
              email: value,
              hubs,
              users,
              linkedclient,
              clientsTenantsCompanycode,
            };
            const result = onChange(modelFields);
            value = result?.email ?? value;
          }
          if (errors.email?.hasError) {
            runValidationTasks("email", value);
          }
          setEmail(value);
        }}
        onBlur={() => runValidationTasks("email", email)}
        errorMessage={errors.email?.errorMessage}
        hasError={errors.email?.hasError}
        {...getOverrideProps(overrides, "email")}
      ></TextField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              id,
              subdomain,
              usesHubs,
              name,
              url,
              uniqueid,
              createdby,
              logo,
              companyname,
              address1,
              address2,
              city,
              state,
              zip,
              phone,
              email,
              hubs: values,
              users,
              linkedclient,
              clientsTenantsCompanycode,
            };
            const result = onChange(modelFields);
            values = result?.hubs ?? values;
          }
          setHubs(values);
          setCurrentHubsValue(undefined);
          setCurrentHubsDisplayValue("");
        }}
        currentFieldValue={currentHubsValue}
        label={"Hubs"}
        items={hubs}
        hasError={errors?.hubs?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("hubs", currentHubsValue)
        }
        errorMessage={errors?.hubs?.errorMessage}
        getBadgeText={getDisplayValue.hubs}
        setFieldValue={(model) => {
          setCurrentHubsDisplayValue(model ? getDisplayValue.hubs(model) : "");
          setCurrentHubsValue(model);
        }}
        inputFieldRef={hubsRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Hubs"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Hubs"
          value={currentHubsDisplayValue}
          options={hubsRecords
            .filter((r) => !hubsIdSet.has(getIDValue.hubs?.(r)))
            .map((r) => ({
              id: getIDValue.hubs?.(r),
              label: getDisplayValue.hubs?.(r),
            }))}
          isLoading={hubsLoading}
          onSelect={({ id, label }) => {
            setCurrentHubsValue(
              hubsRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentHubsDisplayValue(label);
            runValidationTasks("hubs", label);
          }}
          onClear={() => {
            setCurrentHubsDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchHubsRecords(value);
            if (errors.hubs?.hasError) {
              runValidationTasks("hubs", value);
            }
            setCurrentHubsDisplayValue(value);
            setCurrentHubsValue(undefined);
          }}
          onBlur={() => runValidationTasks("hubs", currentHubsDisplayValue)}
          errorMessage={errors.hubs?.errorMessage}
          hasError={errors.hubs?.hasError}
          ref={hubsRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "hubs")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              id,
              subdomain,
              usesHubs,
              name,
              url,
              uniqueid,
              createdby,
              logo,
              companyname,
              address1,
              address2,
              city,
              state,
              zip,
              phone,
              email,
              hubs,
              users: values,
              linkedclient,
              clientsTenantsCompanycode,
            };
            const result = onChange(modelFields);
            values = result?.users ?? values;
          }
          setUsers(values);
          setCurrentUsersValue(undefined);
          setCurrentUsersDisplayValue("");
        }}
        currentFieldValue={currentUsersValue}
        label={"Users"}
        items={users}
        hasError={errors?.users?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("users", currentUsersValue)
        }
        errorMessage={errors?.users?.errorMessage}
        getBadgeText={getDisplayValue.users}
        setFieldValue={(model) => {
          setCurrentUsersDisplayValue(
            model ? getDisplayValue.users(model) : ""
          );
          setCurrentUsersValue(model);
        }}
        inputFieldRef={usersRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Users"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Users"
          value={currentUsersDisplayValue}
          options={usersRecords.map((r) => ({
            id: getIDValue.users?.(r),
            label: getDisplayValue.users?.(r),
          }))}
          isLoading={usersLoading}
          onSelect={({ id, label }) => {
            setCurrentUsersValue(
              usersRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentUsersDisplayValue(label);
            runValidationTasks("users", label);
          }}
          onClear={() => {
            setCurrentUsersDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchUsersRecords(value);
            if (errors.users?.hasError) {
              runValidationTasks("users", value);
            }
            setCurrentUsersDisplayValue(value);
            setCurrentUsersValue(undefined);
          }}
          onBlur={() => runValidationTasks("users", currentUsersDisplayValue)}
          errorMessage={errors.users?.errorMessage}
          hasError={errors.users?.hasError}
          ref={usersRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "users")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              id,
              subdomain,
              usesHubs,
              name,
              url,
              uniqueid,
              createdby,
              logo,
              companyname,
              address1,
              address2,
              city,
              state,
              zip,
              phone,
              email,
              hubs,
              users,
              linkedclient: value,
              clientsTenantsCompanycode,
            };
            const result = onChange(modelFields);
            value = result?.linkedclient ?? value;
          }
          setLinkedclient(value);
          setCurrentLinkedclientValue(undefined);
          setCurrentLinkedclientDisplayValue("");
        }}
        currentFieldValue={currentLinkedclientValue}
        label={"Linkedclient"}
        items={linkedclient ? [linkedclient] : []}
        hasError={errors?.linkedclient?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("linkedclient", currentLinkedclientValue)
        }
        errorMessage={errors?.linkedclient?.errorMessage}
        getBadgeText={getDisplayValue.linkedclient}
        setFieldValue={(model) => {
          setCurrentLinkedclientDisplayValue(
            model ? getDisplayValue.linkedclient(model) : ""
          );
          setCurrentLinkedclientValue(model);
        }}
        inputFieldRef={linkedclientRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Linkedclient"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Clients"
          value={currentLinkedclientDisplayValue}
          options={linkedclientRecords
            .filter((r) => !linkedclientIdSet.has(getIDValue.linkedclient?.(r)))
            .map((r) => ({
              id: getIDValue.linkedclient?.(r),
              label: getDisplayValue.linkedclient?.(r),
            }))}
          isLoading={linkedclientLoading}
          onSelect={({ id, label }) => {
            setCurrentLinkedclientValue(
              linkedclientRecords.find((r) =>
                Object.entries(JSON.parse(id)).every(
                  ([key, value]) => r[key] === value
                )
              )
            );
            setCurrentLinkedclientDisplayValue(label);
            runValidationTasks("linkedclient", label);
          }}
          onClear={() => {
            setCurrentLinkedclientDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchLinkedclientRecords(value);
            if (errors.linkedclient?.hasError) {
              runValidationTasks("linkedclient", value);
            }
            setCurrentLinkedclientDisplayValue(value);
            setCurrentLinkedclientValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks("linkedclient", currentLinkedclientDisplayValue)
          }
          errorMessage={errors.linkedclient?.errorMessage}
          hasError={errors.linkedclient?.hasError}
          ref={linkedclientRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "linkedclient")}
        ></Autocomplete>
      </ArrayField>
      <ArrayField
        lengthLimit={1}
        onChange={async (items) => {
          let value = items[0];
          if (onChange) {
            const modelFields = {
              id,
              subdomain,
              usesHubs,
              name,
              url,
              uniqueid,
              createdby,
              logo,
              companyname,
              address1,
              address2,
              city,
              state,
              zip,
              phone,
              email,
              hubs,
              users,
              linkedclient,
              clientsTenantsCompanycode: value,
            };
            const result = onChange(modelFields);
            value = result?.clientsTenantsCompanycode ?? value;
          }
          setClientsTenantsCompanycode(value);
          setCurrentClientsTenantsCompanycodeValue(undefined);
        }}
        currentFieldValue={currentClientsTenantsCompanycodeValue}
        label={"Clients tenants companycode"}
        items={clientsTenantsCompanycode ? [clientsTenantsCompanycode] : []}
        hasError={errors?.clientsTenantsCompanycode?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks(
            "clientsTenantsCompanycode",
            currentClientsTenantsCompanycodeValue
          )
        }
        errorMessage={errors?.clientsTenantsCompanycode?.errorMessage}
        getBadgeText={(value) =>
          value
            ? getDisplayValue.clientsTenantsCompanycode(
                clientsTenantsCompanycodeRecords.find(
                  (r) => r.companycode === value
                ) ??
                  selectedClientsTenantsCompanycodeRecords.find(
                    (r) => r.id === value
                  )
              )
            : ""
        }
        setFieldValue={(value) => {
          setCurrentClientsTenantsCompanycodeDisplayValue(
            value
              ? getDisplayValue.clientsTenantsCompanycode(
                  clientsTenantsCompanycodeRecords.find(
                    (r) => r.companycode === value
                  ) ??
                    selectedClientsTenantsCompanycodeRecords.find(
                      (r) => r.id === value
                    )
                )
              : ""
          );
          setCurrentClientsTenantsCompanycodeValue(value);
          const selectedRecord = clientsTenantsCompanycodeRecords.find(
            (r) => r.companycode === value
          );
          if (selectedRecord) {
            setSelectedClientsTenantsCompanycodeRecords([selectedRecord]);
          }
        }}
        inputFieldRef={clientsTenantsCompanycodeRef}
        defaultFieldValue={""}
      >
        <Autocomplete
          label="Clients tenants companycode"
          isRequired={false}
          isReadOnly={false}
          placeholder="Search Clients"
          value={currentClientsTenantsCompanycodeDisplayValue}
          options={clientsTenantsCompanycodeRecords
            .filter(
              (r, i, arr) =>
                arr.findIndex(
                  (member) => member?.companycode === r?.companycode
                ) === i
            )
            .map((r) => ({
              id: r?.companycode,
              label: getDisplayValue.clientsTenantsCompanycode?.(r),
            }))}
          isLoading={clientsTenantsCompanycodeLoading}
          onSelect={({ id, label }) => {
            setCurrentClientsTenantsCompanycodeValue(id);
            setCurrentClientsTenantsCompanycodeDisplayValue(label);
            runValidationTasks("clientsTenantsCompanycode", label);
          }}
          onClear={() => {
            setCurrentClientsTenantsCompanycodeDisplayValue("");
          }}
          onChange={(e) => {
            let { value } = e.target;
            fetchClientsTenantsCompanycodeRecords(value);
            if (errors.clientsTenantsCompanycode?.hasError) {
              runValidationTasks("clientsTenantsCompanycode", value);
            }
            setCurrentClientsTenantsCompanycodeDisplayValue(value);
            setCurrentClientsTenantsCompanycodeValue(undefined);
          }}
          onBlur={() =>
            runValidationTasks(
              "clientsTenantsCompanycode",
              currentClientsTenantsCompanycodeValue
            )
          }
          errorMessage={errors.clientsTenantsCompanycode?.errorMessage}
          hasError={errors.clientsTenantsCompanycode?.hasError}
          ref={clientsTenantsCompanycodeRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "clientsTenantsCompanycode")}
        ></Autocomplete>
      </ArrayField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
